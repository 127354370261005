export interface ISubAward {
    pifId: string,
    subAwardObjectName: string,
    primeAwardee: string,
    primeAwardId: string,
    awardee: string,
    awardeeId: string,
    projectName: string,
    awardType: string,
    awardAmount: string,
    endDate: Date,
    startDate: Date,
    awardNumber?: string,
    purposeOfSubAward: string,
    proposedState: string,
    proposedCountry: string,
    vettingCategory:string,
    partnerAwardType?: string,
    primeAwardeeId: string,
    mission: string,
    bureau: string
  }

  let initIISubAward:ISubAward ={
    pifId: "",
    subAwardObjectName: "",
    primeAwardee: "",
    primeAwardId: "",
    awardee: "",
    awardeeId: "",
    projectName: "",
    awardType: "",
    awardAmount: "",
    endDate: new Date(),
    startDate: new Date(),
    awardNumber: "",
    purposeOfSubAward: "",
    proposedState: "",
    proposedCountry: "",
    vettingCategory: "",
    partnerAwardType: "",
    primeAwardeeId: "",
    mission: "",
    bureau: "",
  }
  
  export let initializeISubAward =()=>initIISubAward;