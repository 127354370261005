import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import './Login.css';

import {useEffect } from "react";
import { useNavigate} from "react-router-dom";
import React from "react";
import { authStore } from "../stores/AuthStore";
import { RouteConstants } from "../../App";
import { UserDetails } from "../stores/interfaces/UserDetails";
import { validateToken } from "../../Services/Api";


export default function Alt_login() {
  const navigate = useNavigate();
 

  // useEffect(()=>{
  //   let params = new URLSearchParams(document.location.search)
  //   let code = params.get("code");
  //   if (code){
  //     validateToken(code)
  //     .then(x=>{
  //       setDetails(x);
  //       navigate(RouteConstants.DASHBOARD);
  //     })
  //     .catch(e=>navigate(RouteConstants.ROB));
  //   }
  // },[]);


  return (
    <div className="container-fluid login">
    <div style={{textAlign: "center"}}>
     Logging in 
   </div>
    </div>
  );
}

