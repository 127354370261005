import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Auth from "./components/Login/Auth";
import Privacy from "./components/Privacy/Privacy";
import RulesOfBehavior from "./components/RulesOfBehavior/RulesOfBehavior";
import SubAwardSubmission from "./components/subaward/SubAwardSubmission";
import OrganizationInfo from "./components/subaward/organization/OrganizationInfo";
import AwardInfo from "./components/subaward/awardInfo/AwardInfo";
import ReviewAndSubmit from "./components/subaward/reviewAndSubmit/ReviewAndSubmit";
import KeyIndividuals from "./components/subaward/organization/KeyIndividuals";
import About from "./components/about/AboutComponent";
import Contact from "./components/contact/ContactComponent";
import Success from "./components/subaward/reviewAndSubmit/Success";
import Alt_login from "./components/Login/Alt_login";
import TestDashboard2 from "./components/UserDashBoard/Dashboard/TestDashboard2";
import PrimeAward from "./components/primeawards/PrimeAward";
import NonAward from "./components/nonawards/NonAward";
import ErrorPages from "./components/common/ErrorPage/Error";
import Help from "./components/help/Help";
import RevetSelection from "./components/revet/RevetSelection";
import { useOidc, useOidcAccessToken, withOidcSecure } from "@axa-fr/react-oidc";
import { useEffect } from "react";
import { authStore } from "./components/stores/AuthStore";
import { instance } from "./Services/Api";
import RulesOfBehaviorAlt from "./components/RulesOfBehavior/RulesOfBehaviorAlt";
import Login_forms from "./components/Login/LoginForms";
import { withOidcSecureAlt } from "./Services/WithOidcSecureAlt";
import ReVetSubmission from "./components/revet/ReVetSubmission";
import Beneficiary from "./components/subaward/organization/Beneficiary";

function App() {
    const {isAuthenticated} = useOidc();
    const {accessToken} = useOidcAccessToken()
  const {details, setDetails, testUserToken, isTestUserAuthenticated} = authStore();

    useEffect(() => {
      let token = accessToken?`Bearer ${accessToken}` : `TestToken ${testUserToken}`
      if (!details && (isAuthenticated || isTestUserAuthenticated()))
        instance(token).get("/user").then((resp) => {
          if (resp.status === 200 && resp.data) {
            setDetails(resp.data);
          }
        })
          .catch(error => console.log("Error fetching user details" + error))
    }, [isAuthenticated, testUserToken])
  
  return (
    <div className="container-fluid mainWrapper Background">
      <div className=" headerRegion">
        <Header />
      </div>
      <div className=" routingArea">
        <BrowserRouter>
          <Routes>
            <Route path={RouteConstants.HOME_PAGE} element={<RulesOfBehavior />} />
            <Route path={RouteConstants.ROB} element={<RulesOfBehavior />} />
            <Route path={RouteConstants.ALT_ROB} element={<RulesOfBehaviorAlt />} />
            <Route path={RouteConstants.LOGIN_FORM} element={<Login_forms />} />
            <Route path={RouteConstants.AUTH} element={<Auth />} />
            <Route path={RouteConstants.PRIVACY} element={<Privacy />} />
            <Route path={RouteConstants.ABOUT} element={<About />} />
            <Route path={RouteConstants.HELP} element={<Help />} />
            <Route path={RouteConstants.REVET_SUBMISSION} element={<ReVetSubmission />} />

            
            <Route path={RouteConstants.SUB_AWARD} Component={withOidcSecureAlt(SubAwardSubmission)} >
              {/* <Route path={RouteConstants.SUBAWARD_AWARDEE} element={<SelectFormType />} /> */}
              <Route path={RouteConstants.SUB_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.SUBAWARD_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.SUBAWARD_BENFICIARY} element={<Beneficiary />} />
              <Route path={RouteConstants.SUBAWARD_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.SUBAWARD_REVIEW} element={<ReviewAndSubmit />} />
            </Route>

            {/* Primeawards */}
            <Route path={RouteConstants.PRIME_AWARD} Component={withOidcSecureAlt(PrimeAward)} >
              {/* <Route path={RouteConstants.SUBAWARD_AWARDEE} element={<SelectFormType />} /> */}
              <Route path={RouteConstants.PRIME_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.PRIME_AWARD_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.PRIME_AWARD_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.PRIME_AWARD_REVIEW} element={<ReviewAndSubmit />} />
            </Route>
           
            <Route path={RouteConstants.NON_AWARD} Component={withOidcSecureAlt(NonAward)} >
              {/* <Route path={RouteConstants.SUBAWARD_AWARDEE} element={<SelectFormType />} /> */}
              <Route path={RouteConstants.NON_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.NON_AWARD_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.NON_AWARD_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.NON_AWARD_REVIEW} element={<ReviewAndSubmit />} />
            </Route>

            <Route path={RouteConstants.REVET_SUBMISSION} Component={withOidcSecureAlt(ReVetSubmission)} >
              {/* <Route path={RouteConstants.SUBAWARD_AWARDEE} element={<SelectFormType />} /> */}
              {/* New Routes for Revetting Prime Award, Subaward, Non-Award */}
              <Route path={RouteConstants.REVET_PRIME_AWARD} element={<PrimeAward />} />
              <Route path={RouteConstants.REVET_SUBAWARD} element={<SubAwardSubmission />} />
              <Route path={RouteConstants.REVET_NON_AWARD} element={<NonAward />} />
              </Route>
              
            <Route path={RouteConstants.REVET_SUBAWARD} Component={withOidcSecureAlt(RevetSelection)} >
              <Route path={RouteConstants.REVET_SUBAWARD_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.REVET_SUBAWARD_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.REVET_SUBAWARD_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.REVET_SUBAWARD_REVIEW} element={<ReviewAndSubmit />} />
            </Route>

            {/* Primeawards */}
            <Route path={RouteConstants.REVET_PRIME_AWARD} Component={withOidcSecure(PrimeAward)} >
              <Route path={RouteConstants.REVET_PRIME_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.REVET_PRIME_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.REVET_PRIME_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.REVET_PRIME_REVIEW} element={<ReviewAndSubmit />} />
            </Route>
          
            <Route path={RouteConstants.REVET_NON_AWARD} Component={withOidcSecure(RevetSelection)} >
              <Route path={RouteConstants.REVET_NON_AWARD_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.REVET_NON_AWARD_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.REVET_NON_AWARD_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.REVET_NON_AWARD_REVIEW} element={<ReviewAndSubmit />} />
            </Route>

            <Route path={RouteConstants.SUCCESS} Component={withOidcSecureAlt(Success)} />
            <Route path={RouteConstants.DASHBOARD} Component={withOidcSecureAlt(TestDashboard2)} />

            {/* <Route path={RouteConstants.ALT_LOGIN} element={<Alt_login />} /> */}
            <Route path={RouteConstants.ALT_LOGIN} element={<Alt_login />} />

            <Route path={RouteConstants.CONTACT} element={<Contact />} />
            
            
            <Route path={RouteConstants.ERROR} element={<ErrorPages/>} /> 
            {/* <Route path={RouteConstants.ERROR} element={<PifsFilter />} /> */}

            {/* <Route path={RouteConstants.REGISTER} element={<ErrorPage />} /> */}


          </Routes>
        </BrowserRouter>
      </div>
      <div className=" footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;


export class RouteConstants {
  public static HOME_PAGE: string = "/";
  public static ALT_LOGIN: string = "/alt_login";
  public static LOGIN: string = "/login";
  public static ROB: string = "/rob";
  public static ALT_ROB: string = "/alt_rob";
  public static LOGIN_FORM: string = "/login_forms";
  public static AUTH: string = "/auth";
  public static SUPPORT: string = "/support";
  public static MY_USAID: string = "/myusaid";
  public static ABOUT: string = "/about";
  public static HELP: string = "/help";
  public static REVET_SUBMISSION: string = "/revet";

  public static REVET_PRIME_AWARD: string = "/revet/primeaward"; 
  public static REVET_PRIME_AWARD_INFO: string = "/revet/primeaward/awardinfo";
  public static REVET_PRIME_ORGANIZATION: string = "/revet/primeaward/organization";  
  public static REVET_PRIME_KEY_INDIVIDUAL: string = "/revet/primeaward/keyindividual";
  public static REVET_PRIME_REVIEW: string = "/revet/primeaward/review";
  public static REVET_PRIME_AWARDEE: string = "/revet/primeaward/awardee";

  public static REVET_SUBAWARD: string = "/revet/subaward";
  public static REVET_SUBAWARD_AWARD_INFO: string = "/revet/subaward/awardinfo";
  public static REVET_SUBAWARD_ORGANIZATION: string = "/revet/subaward/organization";  
  public static REVET_SUBAWARD_KEY_INDIVIDUAL: string = "/revet/subaward/keyindividual";
  public static REVET_SUBAWARD_REVIEW: string = "/revet/subaward/review";
  public static REVET_SUBAWARD_AWARDEE: string = "/revet/subaward/awardee";

  public static REVET_NON_AWARD: string = "/revet/nonaward";
  public static REVET_NON_AWARD_AWARD_INFO: string = "/revet/nonaward/awardinfo";
  public static REVET_NON_AWARD_ORGANIZATION: string = "/revet/nonaward/organization";  
  public static REVET_NON_AWARD_KEY_INDIVIDUAL: string = "/revet/nonaward/keyindividual";
  public static REVET_NON_AWARD_REVIEW: string = "/revet/nonaward/review";
  public static REVET_NON_AWARD_AWARDEE: string = "/revet/nonaward/awardee";


  public static NON_AWARD: string = "/nonaward";
  public static NON_AWARD_INFO: string = "/nonaward/awardinfo";
  public static NON_AWARD_ORGANIZATION: string = "/nonaward/organization";
  public static NON_AWARD_KEY_INDIVIDUAL: string = "/nonaward/keyindividual";
  public static NON_AWARD_REVIEW: string = "/nonaward/review";
  public static NON_AWARD_AWARDEE: string = "/nonaward/awardee";


  public static PRIME_AWARD: string = "/primeaward";
  public static PRIME_AWARD_INFO: string = "/primeaward/awardinfo";
  public static PRIME_AWARD_ORGANIZATION: string = "/primeaward/organization";
  public static PRIME_AWARD_KEY_INDIVIDUAL: string = "/primeaward/keyindividual";
  public static PRIME_AWARD_REVIEW: string = "/primeaward/review";
  public static PRIME_AWARD_AWARDEE: string = "/primeaward/awardee";

  public static SUB_AWARD: string = "/subaward";


  public static SUB_AWARD_INFO: string = "/subaward/awardinfo";
  public static SUBAWARD_ORGANIZATION: string = "/subaward/organization";
  public static SUBAWARD_KEY_INDIVIDUAL: string = "/subaward/keyindividual";
  public static SUBAWARD_BENFICIARY: string = "/subaward/beneficiary";
  public static SUBAWARD_REVIEW: string = "/subaward/review";
  public static SUBAWARD_AWARDEE: string = "/subaward/awardee";


  public static REGISTER: string = "/register";
  public static SUCCESS: string = "/success";
  public static DASHBOARD: string = "/dashboard";
  public static PRIVACY: string = "/privacy";
  public static CONTACT: string = "/contact";
  public static ERROR: string = "*";
}

export class AwardTypes {
  public static PRIME_AWARD: string = "primeaward";
  public static NON_AWARD: string = "nonaward";
  public static SUB_AWARD: string = "subaward";
  public static REVET_PRIME_AWARD: string = "revetprimeaward";
  public static REVET_NON_AWARD: string = "revetnonaward";
  public static REVET_SUB_AWARD: string = "revetsubaward";
}

export class FormTypes {
  public static AWARD_INFO: string = "awardinfo";
  public static ORG_INFO: string = "organization";
  public static INDIVIDUAL_INFO: string = "keyindividual";
  public static BENFICIARY: string = "beneficiary";
  public static REVIEW:string="review"
}