import { create } from "zustand";
import { persist } from 'zustand/middleware';
import { AwardTypes, FormTypes, RouteConstants } from "../../App";
import { StoreNames } from "./StoreNames";
import { AWARD_TYPE } from "../../Interfaces/enums";
// Create type for custom hook
export type Workflows = 'individual' | 'organization' | null

type NavState = {
    awardType: string;
    storeFormType: string;
    updateNavStore:(formType: string)=>void;
    updateFormType:(formType: string)=>void;
    clearNavStore:()=>void;
    updateAwardType:(awardtype:string)=>void;
    getRoute:(pathname:string)=>string;
    getAwardTypeRoute:(awardType: string)=>string;
};


export const useNavStore = create<NavState>()(
    persist(
        (set,get) => ({
            awardType:"",
            storeFormType: "",
            updateNavStore: (_workflow: string) => set(() => ({ 
                storeFormType: _workflow })),
            clearNavStore:()=>set(()=>({
                storeFormType: "",
                awardType:""})),
            updateFormType: (_workflow: string) => set(() => ({storeFormType:_workflow })),
            updateAwardType:(awardtype: string)=> set(() => ({awardType: awardtype})),
            getRoute:(pathname:string)=> { 
                const awardType:string= get().awardType;
                const formType: string = get().storeFormType;
                let nextRoute: string="";
                const currentForm = pathname.length>0? pathname.slice(pathname.lastIndexOf("/")+1,pathname.length):"";
                //console.log("awardType => ", awardType, " formtype=> ", formType, " current form=> ", currentForm);
                switch(awardType) {
                    case AwardTypes.PRIME_AWARD:
                        if(currentForm===FormTypes.AWARD_INFO)
                            nextRoute = RouteConstants.PRIME_AWARD_ORGANIZATION
                        else if(currentForm===FormTypes.ORG_INFO)
                            nextRoute = RouteConstants.PRIME_AWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                            nextRoute = RouteConstants.PRIME_AWARD_REVIEW;
                        else nextRoute= RouteConstants.DASHBOARD;
                        break;
                    case AwardTypes.SUB_AWARD:
                        if(currentForm===FormTypes.AWARD_INFO && formType==="organization")
                            nextRoute = RouteConstants.SUBAWARD_ORGANIZATION
                        else if(currentForm===FormTypes.AWARD_INFO && formType==="individual")
                            nextRoute = RouteConstants.SUBAWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.AWARD_INFO && formType==="beneficiary")
                            nextRoute = RouteConstants.SUBAWARD_BENFICIARY
                        else if(currentForm===FormTypes.ORG_INFO)
                            nextRoute = RouteConstants.SUBAWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                            nextRoute = RouteConstants.SUBAWARD_REVIEW;
                        else nextRoute= RouteConstants.DASHBOARD;
                        break;
                    case AwardTypes.NON_AWARD:
                        if(currentForm===FormTypes.AWARD_INFO && formType==="organization")
                            nextRoute = RouteConstants.NON_AWARD_ORGANIZATION
                        else if(currentForm===FormTypes.AWARD_INFO && formType==="individual")
                            nextRoute = RouteConstants.NON_AWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.ORG_INFO)
                            nextRoute = RouteConstants.NON_AWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                            nextRoute = RouteConstants.NON_AWARD_REVIEW;
                        else nextRoute= RouteConstants.DASHBOARD;
                        break;
                        case AwardTypes.REVET_PRIME_AWARD:
                            if(currentForm===FormTypes.AWARD_INFO)
                                nextRoute = RouteConstants.REVET_PRIME_ORGANIZATION
                            else if(currentForm===FormTypes.ORG_INFO)
                                nextRoute = RouteConstants.REVET_PRIME_KEY_INDIVIDUAL
                            else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                                nextRoute = RouteConstants.REVET_PRIME_REVIEW;
                            else nextRoute= RouteConstants.DASHBOARD;
                            break;
                        case AwardTypes.REVET_SUB_AWARD:
                            if(currentForm===FormTypes.AWARD_INFO && formType==="organization")
                                nextRoute = RouteConstants.REVET_SUBAWARD_ORGANIZATION
                            else if(currentForm===FormTypes.AWARD_INFO && formType==="individual")
                                nextRoute = RouteConstants.REVET_SUBAWARD_KEY_INDIVIDUAL
                            else if(currentForm===FormTypes.ORG_INFO)
                                nextRoute = RouteConstants.REVET_SUBAWARD_KEY_INDIVIDUAL
                            else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                                nextRoute = RouteConstants.REVET_SUBAWARD_REVIEW;
                            else nextRoute= RouteConstants.DASHBOARD;
                            break;
                        case AwardTypes.REVET_NON_AWARD:
                            if(currentForm===FormTypes.AWARD_INFO && formType==="organization")
                                nextRoute = RouteConstants.REVET_NON_AWARD_ORGANIZATION
                            else if(currentForm===FormTypes.AWARD_INFO && formType==="individual")
                                nextRoute = RouteConstants.REVET_NON_AWARD_KEY_INDIVIDUAL
                            else if(currentForm===FormTypes.ORG_INFO)
                                nextRoute = RouteConstants.REVET_NON_AWARD_KEY_INDIVIDUAL
                            else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                                nextRoute = RouteConstants.REVET_NON_AWARD_REVIEW;
                            else nextRoute= RouteConstants.DASHBOARD;
                            break;
                    default:
                        nextRoute = RouteConstants.DASHBOARD;
                }   
                return nextRoute;
            },
            getAwardTypeRoute:(awardType: string)=> { 
                const formType: string = get().storeFormType;
                let nextPage: string="";
                 switch(awardType) {
                    case AWARD_TYPE.PRIME_AWARD:
                        nextPage=RouteConstants.PRIME_AWARD_INFO
                        break;
                    case AWARD_TYPE.SUB_AWARD:
                        nextPage=RouteConstants.SUB_AWARD_INFO
                        break;
                    case AWARD_TYPE.NON_AWARD:
                        nextPage=RouteConstants.NON_AWARD_INFO
                        break;
                    case AWARD_TYPE.REVET_SUBMISSION:
                        nextPage=RouteConstants.REVET_SUBMISSION
                        break;
                }   
                return nextPage;
            },
        }),
        { name: StoreNames.Nav, getStorage: () => localStorage })
);

