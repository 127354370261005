import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { OidcProvider } from '@axa-fr/react-oidc';



library.add(
  faCircleInfo,
);

const configuration = {
  client_id: process.env.REACT_APP_CLIENT_ID || "",
  tenant_name: process.env.REACT_APP_TENANT_NAME,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI || "",
  client_secret: process.env.REACT_APP_CLIENT_SECRET || "",
  authorization_url: process.env.REACT_APP_AUTHORIZATION_URL || "",
  token_url: process.env.REACT_APP_TOKEN_URL || "",
  scope: process.env.REACT_APP_SCOPE || "", // offline_access scope allow your client to retrieve the refresh_token
  authority: process.env.REACT_APP_AUTHORITY || "",
  response_code: process.env.REACT_APP_RESPONSE_TYPE || "",

  //silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
  //service_worker_relative_url: '/OidcServiceWorker.js', // just comment that line to disable service worker mode
  //service_worker_only: false,
  //demonstrating_proof_of_possession: false,
};


const Loading = () => <p>Loading</p>;
const AuthenticatingError = () => <p>Authenticating error</p>;
const Authenticating = () => <p>Authenticating</p>;
const SessionLost = () => <p>Session Lost</p>;
const ServiceWorkerNotSupported = () => <p>Not supported</p>;
const CallBackSuccess = () => <p>Success</p>;


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  
  //Comenting out string mode to avoid rerendering twice
  // //<React.StrictMode>
  <OidcProvider configuration={configuration} 
  loadingComponent={Loading}
  authenticatingErrorComponent={AuthenticatingError}
  authenticatingComponent={Authenticating}
  sessionLostComponent={SessionLost}
  //onSessionLost={onSessionLost} // If set "sessionLostComponent" is not displayed and onSessionLost callback is called instead
  serviceWorkerNotSupportedComponent={ServiceWorkerNotSupported}
  callbackSuccessComponent={CallBackSuccess}>
    <App />
    </OidcProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
