import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import "./Contact.css";
import BreadCrumps from "../common/nav/BreadCrumps";
import Box from "@mui/material/Box";

export default function Contact() {
  return (
    <Box>
      <div className="me-auto navContainer">
        <BreadCrumps 
        linkTitle={"Contact Us"}/>
      </div>
      <div style={{ width: "95%", marginLeft: "2em" }}>
        <div className="text-start contactTitleSection">Contact Us</div>
        <div className="contentSection-contact">
          <table className="contact-table">
            <tr>
              <th className="contact-th">Mission</th>
              <th className="contact-th">Email</th>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">Afghanistan</td>
              <td className="contact-td"><a href="mailto:kblaidvsu@usaid.gov">kblaidvsu@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">Iraq</td>
              <td className="contact-td"><a href="mailto:iraqaidvsu@usaid.gov">iraqaidvsu@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">Lebanon</td>
              <td className="contact-td"><a href="mailto:lebanonaidvsu@usaid.gov">lebanonaidvsu@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">Pakistan</td>
              <td className="contact-td"><a href="mailto:pakaidvsu@usaid.gov">pakaidvsu@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">Syria</td>
              <td className="contact-td"><a href="mailto:me-syriaaidvsu@usaid.gov">me-syriaaidvsu@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">West Bank/Gaza</td>
              <td className="contact-td"><a href="mailto:wbgvetting@usaid.gov">wbgvetting@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">Yemen</td>
              <td className="contact-td"><a href="mailto:yemenaidvsu@usaid.gov">yemenaidvsu@usaid.gov</a></td>
            </tr>
          </table>
        </div>
      </div>
      <div className="contactTitleSection"></div>
      <div style={{ width: "95%", marginLeft: "2em" }}>
        <div className="text-start contactTitleSection"><p>
          {" "} For general Bureau of Humanitarian support contact{" "}
          <a href="mailto:HAVettingQuestions@usaid.gov">
            {" "}
            HAVettingQuestions@usaid.gov{" "}
          </a>{" "}
        </p></div>
        <div className="contentSection-contact">
        <table className="contact-table">
            <tr>
              <th className="contact-th">Bureau for Humanitarian Assistance (BHA)</th>
              <th className="contact-th">Email</th>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">BHA Afghanistan</td>
              <td className="contact-td"><a href="mailto:HAVettingAfganistan@usaid.gov">HAVettingAfganistan@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">BHA Iraq</td>
              <td className="contact-td"><a href="mailto:HAVettingIraq@usaid.gov">HAVettingIraq@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">BHA Lebanon</td>
              <td className="contact-td"><a href="mailto:HAVettingLebanon@usaid.gov">HAVettingLebanon@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">BHA Pakistan</td>
              <td className="contact-td"><a href="mailto:HAVettingPakistan@usaid.gov">HAVettingPakistan@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">BHA Syria</td>
              <td className="contact-td"><a href="mailto:HAVettingSyria@usaid.gov">HAVettingSyria@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">BHA West Bank/Gaza</td>
              <td className="contact-td"><a href="mailto:HAVettingWBG@usaid.gov">HAVettingWBG@usaid.gov</a></td>
            </tr>
            <tr className="contact-tr">
              <td className="contact-td">BHA Yemen</td>
              <td className="contact-td"><a href="mailto:HAVettingYemen@usaid.gov">HAVettingYemen@usaid.gov</a></td>
            </tr>
          </table>
        </div>
      </div>
    </Box>
  );
}
