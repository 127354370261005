import React, { useRef, useState } from 'react';
import TilesWithButton from "../../common/Tiles/TileWithButton";
// used for testing functionality
import examplePDF from './PIF-112-0.pdf';
import { IconButton } from 'rsuite';
import {IIndividual} from "../../../Interfaces/IIndividual";
import { instance } from '../../../Services/Api';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { authStore } from '../../stores/AuthStore';



export default function PreviewPIF(props: {pifid:string}) {
  const {testUserToken} = authStore();
  const {accessToken} = useOidcAccessToken();
  const token = useRef<string>(accessToken?`Bearer ${accessToken}` : `TestToken ${testUserToken}`)
  const previewText = [
    "Before completing this PIF submission, preview the PIF form to ensure the accuracy of the information on the form. If any information is not correct, close the form and make any required corrections on screens 1 and 2 above. All fields must be completed.",
  ];

  const pifID: string = props.pifid;
  

    //   const awdID = 'AWD-000-1';
    
    // const sendPreviewData = async () => {
    //   const data = {
    //     primeAward: singlePrimeAward,
    //     // keyIndividual: singleNonAward
    //   }
    // }

const ViewPrimeAwardSectionBE = async () => {

try{

const previewPIF1URL: string =  "/pif/previewPIFPDF";

    const response = await instance(token.current).post(previewPIF1URL, pifID, {
      responseType: 'blob',
      });

      if(response.status === 200) {
        const pdfBlob = new Blob([response.data], {type: 'application/pdf'})

        const url = URL.createObjectURL(pdfBlob);

        window.open(url, '_blank');

    } 
    else {
      console.error('Failed to View PDF')
  }  
}
  
  catch(error){
    console.error('Error during PDF download', error)
}

}

return (

  <> 
  <TilesWithButton
    title={`Preview PIF`} 
    content={previewText}
    onClickButton={ViewPrimeAwardSectionBE}
    buttonName="Preview the PIF Form"
  />
  </>
  );


};





//
//TODO:  Use this function when we are able to retrieve the pdf in an array buffer
// function Download(arrayBuffer:any, type:any) {
//   var blob = new Blob([arrayBuffer], { type: type });
//   var url = URL.createObjectURL(blob);
//   window.open(url);
// }
//

//TODO:  use this to convert PDF string returned from API call to Array Buffer
// var arrayBuf:any ='%PDF-1.4\n1 0 obj\n<< /Type /Catalog /Pages 2 0 R >>\nendobj\n2 0 obj\n<< /Type /Pages /Kids [3 0 R] /Count 1 >>\nendobj\n3 0 obj\n<< /Type /Page /Parent 2 0 R /MediaBox [0 0 300 144] /Contents 4 0 R >>\nendobj\n4 0 obj\n<< /Length 44 >>\nstream\nBT /F1 18 Tf 0 0 Td (Hello, PDF!) Tj ET\nendstream\nendobj\nxref\n0 5\n0000000000 65535 f \n0000000009 00000 n \n0000000074 00000 n \n0000000120 00000 n \n0000000209 00000 n \ntrailer\n<< /Size 5 /Root 1 0 R >>\nstartxref\n255\n%%EOF';


// TODO:  Ultimately we want to do something like this, but can't use 'fs' because its stubbed out in react ---
//        actually a core node module.
// const fs = require('fs');

// function readFileToArrayBuffer(filePath:any) {
//     fs.readFile(filePath, (err:any, data:any) => {
//         if (err) throw err;
//         const arrayBuffer = Uint8Array.from(data).buffer;
//         console.log(arrayBuffer);
//     });
// }

// readFileToArrayBuffer('path/to/your/file.pdf');






// Previev Example from the Front End


// function previewClick() {
//   // console.log ("pdf path: " + pdfURL);   
//   // Using this for now, until we decide how we are retrieving the pdf
//    window.open(examplePDF, '_blank', 'noreferrer');

  
//   // Download(arrayBuf, 'application/pdf');
// }



// var filePath="c/....." 
// var arrayBuf = filePath.getBufArray()
//'%PDF-1.4\n1 0 obj\n<< /Type /Catalog /Pages 2 0 R >>\nendobj\n2 0 obj\n<< /Type /Pages /Kids [3 0 R] /Count 1 >>\nendobj\n3 0 obj\n<< /Type /Page /Parent 2 0 R /MediaBox [0 0 300 144] /Contents 4 0 R >>\nendobj\n4 0 obj\n<< /Length 44 >>\nstream\nBT /F1 18 Tf 0 0 Td (Hello, PDF!) Tj ET\nendstream\nendobj\nxref\n0 5\n0000000000 65535 f \n0000000009 00000 n \n0000000074 00000 n \n0000000120 00000 n \n0000000209 00000 n \ntrailer\n<< /Size 5 /Root 1 0 R >>\nstartxref\n255\n%%EOF';

// const pifID = props.pifID;

//  const [inputData, setInputData] = useState(
//     {
//         "pifID": pifID,
         
//       });




