import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import { Link, useNavigate } from "react-router-dom";
import "./Success.css";
import { RouteConstants } from "../../../App";
import { useEffect } from "react";


function Success () { 
        const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(()=>{
            navigate(RouteConstants.DASHBOARD);
        },6000)
    })
return ( 
    <div className="flex-wrapper fs-3">
        <br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br />
        Your information was submitted successfully. 
        <br></br><br></br><br />
        <div className="mb-2 text-center"> 
                <button className="btn usaid-blue" onClick={()=>navigate(RouteConstants.DASHBOARD)}> Back to Homepage </button>
        </div>

    </div>
)

}
export default Success;