import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import { useState } from 'react';
import "./SelectFormType.css";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import { useFormProps } from "../../stores/FormProperties";
import { PARTNER_AWARD_TYPE } from "../../../Interfaces/enums";


export default function RevetSelectFormType() {
    const { updateFormType, awardType } = useNavStore();
    const { updatePartnerAwardType } = useFormProps();
    const items: { value: string, label: string, imageName: any, color: string,partnerProps: string }[] = [
        { value: 'organization', label: 'Organization', imageName: "../Org_Tile.png", color: "white", partnerProps: PARTNER_AWARD_TYPE.ORGANIZATION},
        { value: 'individual', label: 'Individual', imageName: "../Individual_Tile.png", color: "white", partnerProps: PARTNER_AWARD_TYPE.INDIVIDUAL }
    ];

    // Use name to hold the text  based on awardType value from NavStore:  primeaward | subaward | nonaward
    const name = (awardType === "primeaward") ? "Prime Awardee" : (awardType === "subaward") ? "Subawardee" : (awardType === "nonaward") ? "Non-Awardee" : (awardType === "revet") ? "Re-Vet": null;
    const [value, setValue] = useState("");
    const [hiddenButtons, setHiddenButtons] = useState<boolean>(false);

    const navigate = useNavigate();

    return (
        <div className="container-fluid p-0">
            <h3 className="mb-4 font-weight-italic text-dark">Create Re-Vetting {name} </h3>
            <span className="heading">
                <h4 className="text-center text-dark asterisk">Select Re-Vetting {name} Type</h4>
            </span>
            <div className="btnSection">
                {items.map(item => (
                    <div key={item.value} style={{margin: "20px"}}>

                        <label htmlFor={name + item.value}>
                            <div>
                                <button className="d-flex align-items-center mx-2" type="button" role="button"
                                    
                                    onClick={() => {
                                        // set store value of form type and navigate to appropriate form with path variable
                                        updateFormType(item.value);
                                        updatePartnerAwardType(item.partnerProps)
                                        setHiddenButtons(true);
                                        navigate(RouteConstants.REVET_SUBAWARD_AWARD_INFO, { state: { formType: item.value } })
                                    }}
                                >
                                    <img src={item.imageName}/>
                                </button>
                            </div>
                        </label>

                    </div>
                    // </div>
                ))}
            </div>
        </div>
    );
}


