import axios from "axios";
import IOrganization, { initialiseIOrganization } from "../Interfaces/IOrganization";
import { IIndividual, initializeIIndividual } from "../Interfaces/IIndividual";
import { ISearch } from "../Interfaces/ISearch";
import { ICountry } from "../Interfaces/ICountry";
import { IPicklist } from "../Interfaces/IPicklist";
import { IComment, initializeComment } from "../Interfaces/IComment";
import { IPifSubmission } from "../Interfaces/IPifSubmission";
import {GridKeyIndividual, IGridBeneficiary } from "../App.types";
import { UserDetails } from "../components/stores/interfaces/UserDetails";
import { ICitizenship } from "../Interfaces/ICitizenship";
import { getFileType } from "./Utilities";
import { request } from "http";
import { initializeISubAward, ISubAward } from "../Interfaces/ISubAward";





 const basePath = process.env.REACT_APP_API_URL;

export const instance = (token: string)=>axios.create({
  baseURL:process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers :{ 'Authorization': token}
});

export const instanceCustomHeaders = (token: string, header:string)=>axios.create({
  baseURL:process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers :{ 'Authorization': token, 'Content-Type': header}
});


export const searchOrganizations = async (orgName: string,pifId: string, token: string):Promise<ISearch[]>=>{
  console.log("Searching organization", orgName)
  const resultList: ISearch[]=[];
  
  try{
      await  instance(token).get(basePath+"/organization/search/"+orgName+"/"+pifId,{withCredentials: true})
      .then(response => {
        if(response.status==200 && response.data.length>0){
        for(const x of response.data) resultList.push(x);
        }
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });
  }
  catch(err){
    console.log("Errors encountered", err)
  }
      return resultList;
}




export const getOrganizations = async (objectId: string, token: string):Promise<IOrganization>=>{
    let organizationData:IOrganization = initialiseIOrganization();
    
    try{
         await  instance(token).get("/organization/fetch/"+ objectId)
        .then(response => {
          if(response.status==200 && response.data){
            console.log("respons =>", response.data)
          organizationData = response.data;
        }})
        .catch(error => {
          console.error('Error fetching options:', error);
        });
    }
    catch(err){
      console.log("Errors encountered", err)
    }
        return organizationData;
}


export const searchIndividuals = async (queryParam: string,pifId: string, token: string):Promise<ISearch[]>=>{
  var resultList: ISearch[]=[];
  try{
     await instance(token).get("/individual/search/"+ queryParam+"/"+pifId)
        .then(response => {
          if(response.status==200 && response.data.length>0){
            for(const x of response.data) resultList.push(x);
          }
        })
        .catch(error => {
          console.error('Error fetching options:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
        return resultList;
}

export const getIndividual = async (objectId: string, token: string):Promise<IIndividual>=>{
   
    let individualData:IIndividual=initializeIIndividual();
    try{
       await instance(token).get(basePath+"/individual/fetch/"+ objectId)
          .then(response => {
            if(response.status==200 && response.data){
              individualData =response.data;
              console.log("Individual returned ", individualData)
            }
          })
          .catch(error => {
            console.error('Error fetching options:', error);
          });
        }
      catch(err){
        console.log("Errors encountered", err)
      }
      return individualData;
}

export const getIndividualByIndId = async (indId: string, token: string):Promise<IIndividual>=>{
   
  let individualData:IIndividual=initializeIIndividual();
  try{
     await instance(token).get(basePath+"/individual/"+ indId)
        .then(response => {
          if(response.status==200 && response.data){
            individualData =response.data;
            console.log("Individual returned ", individualData)
          }
        })
        .catch(error => {
          console.error('Error fetching options:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return individualData;
}

export async function getSubawardByObjectName(objectName: string, token: string): Promise<ISubAward>{

  let subawardData:ISubAward=initializeISubAward();
  try{
     await instance(token).get(basePath+"/subawardByObjectName/"+ objectName)
        .then(response => {
          if(response.status==200 && response.data){
            subawardData =response.data;
            console.log("Subaward returned ", subawardData)
          }
        })
        .catch(error => {
          console.error('Error fetching options:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return subawardData;
}

export const getCountries= async (token: string):Promise<ICountry[]>=>{
   
  let countryData:ICountry[]=[];
  try{
    
     await instance(token).get(basePath+"/country")
        .then(response => {
          if(response.status==200 && response.data){
            countryData = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching country data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return countryData;
}

//Fetch picklist by name 
export const getPicklist= async (name:string, token: string):Promise<IPicklist[]>=>{
  let picklistData:IPicklist[]=[];
  try{
    
     await instance(token).get("/picklist/picklistByName/"+name)
        .then(response => {
          if(response.status==200 && response.data){
            picklistData = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching picklist data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return picklistData;
}

export const uploadFile = (file: File, PifId: string, DocumentType: string, DocumentId: string,  DocumentSubType: string,token: string) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  formData.append("files", file);
  formData.append("PifId", PifId);
  formData.append("OrgId", PifId);
  formData.append("DocumentType", DocumentType);
  formData.append("DocumentSubType", DocumentSubType);
  formData.append("DocumentId", DocumentId);


  instanceCustomHeaders(token,"multipart/form-data").post( "/document/upload", formData)
    .then((response) => {
      // window.confirm("File uploaded successfully" + response);
      console.log("File uploaded successfully", response);
    })
    .catch((error) => {
      // window.confirm("File uploaded failed" + error + formData + basePath);
      console.error("Error uploading file:", error);
    });
    
}

export const uploadCitizenshipFile = (file: File, CitId: string, DocumentType: string, DocumentSubType: string, DocAssocId: string, token: string) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  formData.append("file", file);
  formData.append("CitId", CitId);
  formData.append("DocumentType", DocumentType);
  formData.append("DocumentSubType", DocumentSubType);
  formData.append("DocAssocId",DocAssocId)

  instanceCustomHeaders(token,"multipart/form-data").post( "/documents/citUpload", formData)
    .then((response) => {
      // window.confirm("File uploaded successfully" + response);
      console.log("File uploaded successfully", response);
    })
    .catch((error) => {
      // window.confirm("File uploaded failed" + error + formData + basePath);
      console.error("Error uploading file:", error);
    });
    
}

export const createOrUpdateKeyIndvidual = (keyInd: IIndividual, file:Array<any>, token:string) => {
  const headers = {
    "Content-Type": "application/json",
  };

  console.log(keyInd);
  
  console.log(JSON.stringify(keyInd));
  alert("Starting update");

  instanceCustomHeaders(token,"application/json").post("/individual/createOrUpdate", JSON.stringify(keyInd))
  .then(response => {
    //alert("in RESPONSE");
    console.log("data" + JSON.stringify(response.data))
    console.log("cit" + JSON.stringify(response.data.citizenship));
    console.log("cit1" + JSON.stringify(response.data.citizenship[0].individualId));
    const responseCit = response.data.citizenships;
    //createOrUpdateCitFiles(responseCit,file)
  })
  .catch(error => {
    console.error('Error fetching options:', error);
    alert('Error fetching options:' + error);
  });

  
}

export const createOrUpdateCitFiles = (citizenships: ICitizenship[], files: File[], token: string) =>{

  console.log("insde CreateOrUpdateCitFiles")
  for (var i = 0; i < citizenships.length; i++) {

    var file = files.length > i ? files[i] : null;
    var citizenship = citizenships[i];

    if (file){
      uploadCitizenshipFile(files[i], citizenship.individualId, citizenship.governmentIdentificationT, "subdoctype","docAssocId", token);
    }    
  }

}

export const postComments= async (comment: IComment, pifId: string, token: string):Promise<IComment>=>{
  console.log("Received comment for post", comment)
  let result: IComment=initializeComment();
  
  try{
    await  instance(token).post("/subaward/comments/"+pifId,comment)
          .then(response => {
            if(response.status===201 && response.data.length>0){
               result={...response.data};
               console.log("Returned data is ",response.data);
          }})
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    console.log("Errors encountered", err)
  }
      return result;
}

export const getComments= async (pifId: string, token: string):Promise<IComment[]>=>{
  const result: IComment[]=[];

  try{
    await  instance(token).get("/subaward/comments/"+pifId)
          .then(response => {
            if(response.status===200 && response.data.length>0){
            for(const x of response.data) result.push(x);
            }
          })
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    console.log("Errors encountered", err)
  }
      return result;
}

export const updateComments =  async (input:string[], token: string)=>{
  console.log("Set comment IDs to read", input)

  try{
    await  instance(token).post("/subaward/viewedComments",input)
          .then(response => {
            if(response.status===201 && response.data.length>0){
               
               console.log("Result of updating comment is ",response.data);
          }})
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    console.log("Errors encountered", err)
  }
}

export const submitBeneficiaries= async (submission: IPifSubmission, token: string):Promise<Boolean>=>{

  let result: Boolean = true;
  
  try{
    await  instance(token).post("/subaward/submitBeneficiaries",submission)
          .then(response => {
            if(response.status===201 ){
               result={...response.data};
               console.log("Returned data is ",response.data);
          }})
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    result=false;
    console.log("Errors encountered", err)
  }
      return result;
}


export const postSubAward= async (submission: IPifSubmission, token: string):Promise<Boolean>=>{
  console.log("Received comment for post", submission)
  let result: Boolean = true;
  
  try{
    await  instance(token).post("/subaward/submitpif",submission)
          .then(response => {
            if(response.status===201 ){
               result={...response.data};
               console.log("Returned data is ",response.data);
          }})
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    result=false;
    console.log("Errors encountered", err)
  }
      return result;
}

export const getSubawardsByPifId= async (pifId:string, token: string):Promise<ISubAward[]>=>{
  let subawards:ISubAward[]=[];
  try{
    
    await instance(token).get("/subawards/"+pifId)
        .then(response => {
          if(response.status==200 && response.data){
            subawards = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching subaward data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    console.log(subawards);
    return subawards;
}

export const getIndividualsByPifId= async (pifId:string, token: string):Promise<IIndividual[]>=>{
  let ind:IIndividual[]=[];
  try{
    
    await instance(token).get("/individuals/"+pifId)
        .then(response => {
          if(response.status==200 && response.data){
            ind = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching individual data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    console.log(ind);
    return ind;
}

export const keyIndividualsToGridKeyIndividual=(keyIndv:IIndividual[]):GridKeyIndividual[]=>{

  let resultList:GridKeyIndividual[]=[];

  keyIndv.forEach(ind =>
    {
      let gridInd:GridKeyIndividual={
        cellId:"",
        id: "",
        name:"",
        role: "",
        typeId: "",
      }
        
      gridInd.cellId=ind?.indId;
      if(ind?.citizenship && ind?.citizenship.length>0) {
        gridInd.id=ind?.citizenship[0]?.governmentIssuedPhotoId ||"";
        gridInd.typeId=ind?.citizenship[0]?.governmentIdentificationT||"";
      }
      gridInd.name=(ind?.firstName+ " " + ind?.middleName + " " + ind?.lastName);
      gridInd.role=ind?.organizationTitleOrRank;
      resultList.push(gridInd);
    }
    
  ) //End ForEach
    
    return resultList;
}

export const subawardsToGridBeneficiaries=(subawards:ISubAward[]):IGridBeneficiary[]=>{

  let resultList:IGridBeneficiary[]=[];

  subawards.forEach(subaward =>
    {
      let beneficiary:IGridBeneficiary={
        cellId:"",
        name:"",
        id:"",
        location:"",
        startDate:"",
        endDate:"",
        amount:"",
      }
        
      beneficiary.cellId = subaward?.awardeeId;
      beneficiary.id = subaward?.awardeeId;
      beneficiary.name = subaward?.awardee;
      beneficiary.location = subaward?.proposedCountry;
      beneficiary.startDate = new Date(subaward?.startDate).toLocaleDateString();
      beneficiary.endDate = new Date(subaward?.endDate).toLocaleDateString();
      beneficiary.amount = subaward?.awardAmount;

      resultList.push(beneficiary);
    }
    
  ) //End ForEach
    
    return resultList;
}

export const getOrganizationByPifId= async (pifId:string,token: string):Promise<IOrganization>=>{
  let org:IOrganization=initialiseIOrganization();

  try{
    await instance(token).get("/organization/"+pifId)
        .then(response => {
          if(response.status==200 && response.data){
            org = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching individual data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return org;
}

export const getOrganizationFilesByPifId= async (pifId:string):Promise<any[]>=>{
 /*
  let files:IorganizationUpload =[]
  try{
    await axios.get(basePath+"/getOrgDocs/"+pifId)
        .then(response => {
          if(response.status==200 && response.data){
            files = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching individual data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return files;
    */

    return []
}

export const createOrUpdateOrg= (org: IOrganization, file:Array<any>,token: string) => {

  console.log("Organization being passed ",org);
  const headers = {
    "Content-Type": "application/json",
  };

  instanceCustomHeaders(token,"application/json").post("/organization/createOrUpdate", JSON.stringify(org))
  .then(response => {
    if(response.status==200 && response.data){
      console.log("success");
    }
  })
  .catch(error => {
    console.error('Error fetching options:', error);
    alert('Error fetching options:' + error);
  });

}

export const validateToken = async (code:string,token: string): Promise<UserDetails> => {
  let userDetail:UserDetails = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    middleName: "",
    isActive: ""
  }

  const URL= basePath + "/authenticate";

  await instance(token).post("/authenticate/"+code)
  .then(response => {
    if(response.status===200 && response.data){
      userDetail = response.data;
    }
  })
  .catch(error => {
    console.error('Error fetching options:', error);
    alert('Error fetching options:' + error);
    throw error;
  });

  return userDetail;
}


export const createPif = async(data:any, token:string):Promise<string> =>{ 
  return await instance(token).put("/subaward/createupdate/", data)
    .then(response => {

      alert(response.status + " : created");
      // setting new pifID
      return response.data.pifId;
      // console.log("In axios put - New pif ID: " + newPIFID);
    })

    .catch(error => {
      return null;
      //prompt("error",JSON.stringify(URL));
      //prompt("error",JSON.stringify(error.request));
      console.error('Error fetching options:', error);
    });
}

export async function createBeneficiaryPif(data:any, token:string):Promise<string> { 
  return await instance(token).put("/beneficiary/createUpdatePif/", data)
    .then(response => {

      return response.data.pifId;
    })

    .catch(error => {
      return null;
      console.error('Error fetching options:', error);
    });
}

export async function createUpdateBeneficiary(data:any, token:string):Promise<string> { 
  return await instance(token).put("/beneficiary/createUpdate/", data)
    .then(response => {

      return response.data.pifId;
    })

    .catch(error => {
      return null;
      console.error('Error fetching options:', error);
    });
}

export const viewFile = async (filename: string, token: string, viewDocumentUrl: string) => {

  const response = await instance(token).get(viewDocumentUrl, {
    responseType: 'blob',
    });

  if(response.status === 200) {
    const blob = new Blob([response.data], {type: getFileType(filename)})

    const url = URL.createObjectURL(blob);

    window.open(url, '_blank');
  }
};

export const logoutApi = async(token: string)=>{ 
  return await instance(token).post("/logout")
    .then(response => {
      // setting new pifID
    })
    .catch(error => {
      console.error('Error fetching options:', error);
    });
}