import IAddress from "./IAddress";
import { ICitizenship } from "./ICitizenship";

 export interface IIndividual {
	indId: string;
	pifId: string;
	dateOfBirth: string;
	birthState: string;
	countryOfBirth: string;
	country: string;
	alternativeCountryCode: string;
	primaryCountryCode: string;
	idCountryOfIssuance: string;
	govIssuePhotoIdType: string;
	govIssuePhotoIdTypeMultipleA: string;
	govIssuePhotoIdTypeMultipleB: string;
	completeGovIssuePhotoIDNumber: string;
	completeGovIssuePhotoIDNumberMultipleA: string;
	completeGovIssuePhotoIDNumberMultipleB: string;
	lawfulPermanentResidentCardNumber: string;
	organizationTitleOrRank: string;
	firstName: string;
	lastName: string;
	middleName: string;
	nameOfIndividual: string;
	mission: string;
	licensesAndCertifications: string[];
	associatedOrgId: string;
	legacyId: string;
	awardeeId: string;
	birthDistrict: string;
	birthCity: string;
	gender: string;
	occupation: string;
	tribalAffiliation: string;
	citizenship: ICitizenship[];
	address: IAddress[];
	district: string;
	villageCity: string;
	stateRegion: string;
	usCitizen: boolean;
	permResident: boolean;
	organizationName: string;
	phone: string;
	alternatePhone: string;
	email: string;
	alternateEmail: string;
	isCellPhone: boolean;
	isAlternatePhoneCellPhone: boolean;
	title: string;
	pifIdIndividual: string;
	idCountryOfIssuanceMultipleA: string;
	idCountryOfIssuanceMultipleB: string;
	bureau: string;
	otherFirstName: string[];
	otherMiddleName: string[];
	otherLastName: string[];
}


let initIIndividual:IIndividual ={
	indId: "",
	pifId: "",
	dateOfBirth: "",
	birthState: "",
	countryOfBirth: "",
	country: "",
	alternativeCountryCode: "",
	primaryCountryCode: "",
	idCountryOfIssuance: "",
	govIssuePhotoIdType: "",
	govIssuePhotoIdTypeMultipleA: "",
	govIssuePhotoIdTypeMultipleB: "",
	completeGovIssuePhotoIDNumber: "",
	completeGovIssuePhotoIDNumberMultipleA: "",
	completeGovIssuePhotoIDNumberMultipleB: "",
	lawfulPermanentResidentCardNumber: "",
	organizationTitleOrRank: "",
	firstName: "",
	lastName: "",
	middleName: "",
	nameOfIndividual: "",
	mission: "",
	licensesAndCertifications: [],
	associatedOrgId: "",
	legacyId: "",
	awardeeId: "",
	birthDistrict: "",
	birthCity: "",
	gender: "",
	occupation: "",
	tribalAffiliation: "",
	citizenship: [],
	address: [],
	district: "",
	villageCity: "",
	stateRegion: "",
	usCitizen: false,
	permResident: false,
	organizationName: "",
	phone: "",
	alternatePhone: "",
	email: "",
	alternateEmail: "",
	isCellPhone: false,
	isAlternatePhoneCellPhone: false,
	title: "",
	pifIdIndividual: "",
	idCountryOfIssuanceMultipleA: "",
	idCountryOfIssuanceMultipleB: "",
	bureau: "",     
	otherFirstName: [],
	otherMiddleName: [],
	otherLastName: [],  
}

export let initializeIIndividual =()=>initIIndividual;