import "./Tiles.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default function TilesWithButton(props: {
  title: string;
  content: string[];
  subHeadings?: boolean;
  onClickButton: () => void;
  buttonName: string;
}) {
  // const [isChecked, setIsChecked]= useState(false);
  return (
    <div>
      <div className="text-start robSection">{props.title}</div>

      <div className="rounded-2 textFormat contentSection-redesign">
        {props.content.map((item, idx) => {
          return (
            <span key={idx}>
              <p>{item}</p>
            </span>
          );
        })}
        <div className="text-center">
          <button className="rounded-2 loginButton mx-auto fs-6 p-2 mt-2" onClick={props.onClickButton}>
            {props.buttonName}
          </button>
        </div>
      </div>
    </div>
  );
}
