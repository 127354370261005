import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import { HelpConstants } from "./HelpConstants";
import pdfIcon from "../../assets/images/svg/pdf.svg";
import { LinkBodyContent } from "./LinkBodyContent";
import React, { useState } from "react";
import BreadCrumps from "../common/nav/BreadCrumps";
import { RouteConstants } from "../../App";
  
export default function Help() {
  var links = new HelpConstants();
  var content = new LinkBodyContent()
  var [displayedContent, setDisplayedContent] = useState(content.HelpLinks[0].content)
  
  function setContentClick(event:any){
    var selectedText:string = event.target.innerText;
    var linkContents = new LinkBodyContent();

    var selectedContent = linkContents.HelpLinks.find((item)=>item.title === selectedText);
    
    if(selectedContent !== undefined){
      setDisplayedContent(selectedContent.content);
    }
  }

  return (
    <div className="container-fluid">
      <div className="me-auto navContainer">
        <BreadCrumps 
        linkTitle={"Help"}/>
      </div>
      <div className="row" style={{marginTop: 25}}>
        <div className="col-8">
          {displayedContent}
        </div>
        <div className="col-4">
          <h5 style={{color:"white", backgroundColor: "#0164B9"}}>PVS Portal Help</h5>
            <div className="contentSection-help textFormat">
              {links.HelpLinks.map((item) => (
                <div className="row">
                  <div>
                  <button type="button" className="btn-link" style={{backgroundColor: "#f8f8f8"}}
                          onClick={setContentClick}>
                    {item.title}
                  </button>
                  </div>
                  {item.subTitle && 
                  (<>
                  {item.subTitle.map((sub)=>(
                    <div>
                    <button type="button" className="btn-link" style={{backgroundColor: "#f8f8f8", marginLeft: 10}}
                            onClick={setContentClick}>
                      {sub}
                    </button>
                  </div>
                  ))}
                  </>)}
                </div>
              ))}
              <div style={{borderTop:"2px solid grey", marginTop: 10 }}>
                <button type="button" className="btn-link" style={{backgroundColor: "#f8f8f8", marginTop: 5}}>
                  <img src={pdfIcon} alt="logo" /> PVS Portal User Guide (PDF)
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}