import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import Tiles from "../common/Tiles/Tiles";
import "./AboutComponent.css";

const aboutItems = [
  {
    title: "USAID PVS Portal",
    content: [
      "You are using an official United States Government system, which may be used only for authorized U.S. Government purposes. Unauthorized access or use of this system may subject you to administrative, civil, or criminal actions, as well as fines or other penalties. In accordance with Federal Regulations, employees have a duty to protect and conserve Government property and shall not use such property, or allow its use, for other than authorized purposes.",
      "\n",
      "This computer system may be monitored and information disclosed for any lawful purposes, including for the management and maintenance of the system, to ensure that the system is authorized to facilitate protection against unauthorized access, and to verify security procedures, survivability and operational security.",
      "\n",
      "You have no reasonable expectation of privacy while using this system. Use of this system by any user, authorized or unauthorized, constitutes express consent to this monitoring.",
    ],
  },
  {
    title: "Privacy Notice",
    content: [
      "Purpose: Partner Vetting System and PVS Portal is USAID's interactive case management application to assist USAID in managing and facilitating vetting requests of awardees. The Agency adapted a vetting process for proposed awardees to guard against the possibility that the United States Government could inadvertently provide financial support to an individual or entity that supports, is involved with, or otherwise engaged in terrorists, terrorism, or other illicit activities that are inconsistent with the interests of U.S. national security or the foreign policy interests of the United States, or that could put Agency personnel in harm's way.",
      "\n",
      "Authority: The following legal authorities authorize the collection of information: 5 U.S.C. Section 301, Departmental Regulations; 22 U.S.C. 2151 et seq.; and USAID Automated Directive System (ADS) 201 - Program Cycle.",
      "\n",
      "How USAID uses the information collected:Information collected by this site/system will be used to properly and fully identify the organizational entity and individual through vetting exercises in external intelligence databases. The information may also be used to the extent that such use or disclosure is permissible or required by law, and the use or disclosure complies with and is limited to the relevant requirements of such law.",
      "\n",
      "Disclosure of your Personal Information is Voluntary: However, if you fail to provide the requested information, we will be unable to conduct the vetting required for consideration of USAID for contracts, grants, cooperative agreements, or other award procurement.",      "",
    ],
  },
  {
    title: "Accessibility",
    content: [
      "USAID is committed to providing access to all individuals seeking information on usaid.gov. To provide this information, this site has been built to comply with Section 508 of the Rehabilitation Act (as amended). Section 508 requires that all individuals with disabilities (whether federal employees or members of the general public) have access to and use of information and data, comparable to that provided to individuals without disabilities, unless an undue burden would be imposed on us. If you use assistive technology (such as a screen reader, Braille reader, etc.) and have trouble accessing information on this site, please contact us with the nature of the problem, the URL (web address) of the information you tried to access, and your contact information. We will contact you as soon as possible, and do what we can to provide you with the information you need.",
    ],
  },
  {
    title: "Accessing Documents in Different File Formats",
    content: [
      "Some documents on usaid.gov are in Portable Document Format (PDF), so you can view publications in their original format regardless of the type of computer, browser, or operating system you use to access the Internet. In order to view, navigate through, and print PDF documents, you must first download the free Acrobat Reader software. For best results, you should install version 3.0 or later. Visit the Adobe Reader Help and Support page for troubleshooting tips. If you use a screenreader, you may want to visit Adobe's Reader accessibility page, which has a user's guide for accessing PDF documents with assistive technology.",
    ],
  },
];


export default function About() {

  return (
    <div className="container-fluid">
    
      {aboutItems.map((item) => (
        <div className="row textFormat line-break" style={{ padding: "15px" }}>          
          <Tiles title={item.title} content={[...item.content] } /> 

        </div>
      ))}
    </div>
  );
}
