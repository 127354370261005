import { OidcClient, StringMap } from '@axa-fr/oidc-client';
import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { authStore } from '../components/stores/AuthStore';
import { JSX } from 'react/jsx-runtime';

export type OidcSecureProps = {
  callbackPath?: string;
  extras?: StringMap;
  configurationName?: string;
};

export const OidcSecureAlt: FC<PropsWithChildren<OidcSecureProps>> = ({
    children,
    callbackPath,
    extras,
    configurationName = 'default',
}) => {
  const {isTestUserAuthenticated} = authStore();
    
  const getOidc = OidcClient.get;
  const oidc = getOidc(configurationName);
  useEffect(() => {
    console.log("User authentication status ", isTestUserAuthenticated())
    if (!(oidc.tokens || isTestUserAuthenticated())) {
        oidc.loginAsync(callbackPath || undefined, extras || undefined)
    }
  }, [configurationName, callbackPath, extras,oidc,isTestUserAuthenticated()]);

  if (!(oidc.tokens || isTestUserAuthenticated())) {
    return null;
  }
  return <>{children}</>;
};

export function withOidcSecureAlt<T extends object>
  (
    WrappedComponent: FC<T>,
  callbackPath?: string,
  extras?: StringMap,
  configurationName: string = 'default'
  ) : FC<T>
    {
        return (props: T) => (
          <OidcSecureAlt 
            callbackPath={callbackPath} 
            extras={extras} 
            configurationName={configurationName}
          >
            <WrappedComponent {...props} />
          </OidcSecureAlt>
        );
      }
