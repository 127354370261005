import "./SubAwardSubmission.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import {useState,} from 'react';
import icon_people from "../../assets/images/svg/people.svg";
import icon_person from "../../assets/images/svg/person.svg";
import { Outlet, useLocation } from "react-router-dom";
import FormNavigation from "./formNavigation/FormNavigation";
import SelectFormType from "./selectFormType/SelectFormType";
import { useNavStore } from "../stores/NavStore";
import BreadCrumps from "../common/nav/BreadCrumps";

export default function SubAwardSubmission () {

  const {storeFormType} = useNavStore();
    const items: {value: string, label:string, iconName: string, iconClass: string }[] = [
      { value: 'organization', label: 'Organization', iconName: icon_people, iconClass: "bi-people" },
      { value: 'individual', label: 'Individual', iconName: icon_person, iconClass: "bi-person"}
    ];


    // const {setFormType,enabledForms,formType,switchForm} = FormStore();
    const [toggleAwardeeAndFormNav,setToggleAwardeeAndFormNav] = useState(true);
    // Object for left nav links
    const navLinks =  
    {
      linkState: 'Awardee Type',
      pageTitle: "Select Award",
      breadCrumbTextLink: 'Select Awardee',
      homeLinkText: 'PVSportal',
    };


    const breadCrumbLinkText: string = "Select Awardee Type" ;
  
    const [value,setValue] = useState<string | null>(null);

    let breadCrumbTextLink = 'Select Awardee Type';


    const [selectedRadioBtn, setSelectedRadioBtn ] = useState('');
    const [ btnVisibility, toggleBtnVisibility] = useState(false);
    const indId:any = document.querySelector("#awardeeindividual");


  //*Get location 
  const urlLolcation = useLocation();
  function getCurrentUrl(){
    return urlLolcation.pathname as string
  }
  // Set state to hide/show elements based on SUB, PRIME, or NONAWARD
  const [showPrimeAward, setShowPrimeAward] = useState(true);
  const [showSubAward, setShowSubAward] = useState(false);
  const [showNonAward, setShowNonAward] = useState(false);


    return (
    <>
    {/* MAIN */}
      <div className="me-auto navContainer">
        <BreadCrumps/>
      </div>
      <div className="subAwardWrapper">
        {(storeFormType.length>0)?<FormNavigation/>:<SelectFormType/>}
      <div>
  
        <Outlet/>

      </div>
    </div>
    </>
    );
}