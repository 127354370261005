
import './Tiles.css'
import "bootstrap/dist/css/bootstrap.min.css";


export default function TilesWithBullets(props: {
        title: string;
        content: string[];
      }) {
        const mergedTextWithParagraphs = (items: string[]) => {
          return items.map((item, idx) => {
              return( <li key={idx}>
                <p>{item}</p>
              </li>  
              )    
        });
        };
      
        return (
          <>
            <div className="text-start robSection">{props.title}</div>
      
            <div className="contentSection-redesign textFormat">
              <ul>
              {mergedTextWithParagraphs(props.content)}
              </ul>
            </div>
          </>
        );
      }

