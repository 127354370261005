import "../../common/Tiles/Tiles.css";
import "../../../index.css"
import "bootstrap/dist/css/bootstrap.css";
import OrganizationSearch from "./OrganizationSearch";
import AdditionalEmail from "./AdditionalEmail";
import AdditionalLicense from "./AdditionalLicense";
import DualCitizenship from "./DualCitizenship";
import { useEffect, SyntheticEvent, useState, ChangeEvent } from "react";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme
import { ColDef } from "ag-grid-community";
import { IGridBeneficiary, IaddressControlAttributes, IcontrolAttributes } from "../../../App.types";
import DeleteCellRenderer from "./DeleteCellRenderer";
import EditCellRenderer from "./EditCellRenderer";
import UploadControl from "../../common/UploadControl";
import AdditionalIsCellPhoneNumber from "./AdditionalIsCellPhoneNumber";
import { ISearch } from "../../../Interfaces/ISearch";
import { IIndividual, initializeIIndividual } from "../../../Interfaces/IIndividual";
import { createOrUpdateCitFiles, viewFile, getIndividual, instance, getIndividualByIndId, createPif, subawardsToGridBeneficiaries, getSubawardsByPifId, getSubawardByObjectName, createUpdateBeneficiary } from "../../../Services/Api";
import { checkNullObject, checkNullValue, getFileType } from "../../../Services/Utilities";
import { getPicklist, getIndividualsByPifId, keyIndividualsToGridKeyIndividual } from "../../../Services/Api";
import { IPicklist } from "../../../Interfaces/IPicklist";
import HelpDialog from "../../common/HelpDialog";
import { DatePicker } from "rsuite";
import { ICitizenship } from "../../../Interfaces/ICitizenship";
import { initialize5ICitizenship, initializeICitizenship } from "../../../Interfaces/ICitizenship";
import { filterEmptyCitizenship } from "../../../Services/Utilities";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { textBirth, textLawful, textCitizenship, textDual } from "./KeyIndividualConstants";
import { RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import { useFormProps } from "../../stores/FormProperties";
import IAddress from "../../../Interfaces/IAddress";
import { useCountryStore } from "../../stores/CountryStore";
import { format } from 'date-fns';
import IndividualAddressField from "./IndividualAddressField";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { Dropdown } from 'react-bootstrap'
import { v4 as uniqueKey } from "uuid";
import React from "react";
import { Errors } from "../awardInfo/validations/AwardInfoValidations";
import { initializeISubAward, ISubAward } from "../../../Interfaces/ISubAward";
import { PARTNER_AWARD_TYPE } from "../../../Interfaces/enums";
import { useAwardeeStore } from "../../stores/AwardeeStore";
import { authStore } from '../../stores/AuthStore';


export default function Beneficiary() {

  const { accessToken } = useOidcAccessToken();
  const {testUserToken} = authStore();
  const token = useRef<string>(accessToken?`Bearer ${accessToken}` : `TestToken ${testUserToken}`);
  const { storeFormType, getRoute } = useNavStore();
  const { countries, updateCountries } = useCountryStore();
  const { storedPifId, disableFormFields, storedMission, storedBureau, storedOrganisationId, storedAwardType } = useFormProps();
 
  const { state, pathname } = useLocation();
  const pifId = useRef<string>("");
  let navigate = useNavigate();

  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [birthCountry, setBirthCountry] = useState<string>('');
  const [primCitCountryIssuedBy, setPrimCitCountryIssuedBy] = useState<string>('');
  const [primeGovIdentNum, setPrimeGovIdentNum] = useState<string>('');
  const [otherCitizenships, setOtherCitizenships] = useState<ICitizenship[]>(initialize5ICitizenship);
  const [message, setMessage] = useState<string | null>(null);
  const [orgIdentityTypes, setOrgIdentityTypes] = useState<IPicklist[]>([]);
  const [subawards, setSubawards] = useState<ISubAward[]>([]);
  const [reRender, setReRender] = useState<number>(0);
  const [selectedGovernmentIdentification, setGovernmentIdentificationType] = useState<string>('');
  const [selectedGovernmentIssuedID, setGovernmentIssuedID] = useState<string>('');
  var [indId, setIndId] = useState("");
  const [firstName, setFirstName] = useState("");
  var [middleName, setMiddleName] = useState("");
  var [lastName, setLastName] = useState("");
  var [otherFirstName, setOtherFirstName] = useState("");
  var [otherMiddleName, setOtherMiddleName] = useState("");
  var [otherLastName, setOtherLastName] = useState("");
  var [rank, setRank] = useState("");
  var [idNumber, setIdNumber] = useState("");
  var [dualCitizenship, setDualCitizenship] = useState("");
  var [cityVillage, setCityVillage] = useState("");
  var [district, setDistrict] = useState("");
  var [governorate, setGovernorate] = useState("");
  var [dob, setDob] = useState<Date | null | undefined>();
  var [tribal, setTribal] = useState("");
  var [organizationName, setOrganizationName] = useState("");
  var [usCitizen, setUsCitizen] = useState("");
  var [permResident, setPermResident] = useState("");
  var [permResidentIdNumber, setPermResidentIdNumber] = useState("");
  var [isCellPhone, setIsCellPhone] = useState("");
  var [isAdditionalCellPhone, setIsAdditionalCellPhone] = useState("");
  var [phoneOne, setPhoneOne] = useState("");
  var [phoneTwo, setPhoneTwo] = useState("");
  var [countryCodeOne, setCountryCodeOne] = useState("");
  var [countryCodeTwo, setCountryCodeTwo] = useState("");
  var [emailOne, setEmailOne] = useState("");
  var [emailTwo, setEmailTwo] = useState("");
  var [licenseOne, setLicenseOne] = useState("");
  var [licenseTwo, setLicenseTwo] = useState("");
  var [primaryCountry, setPrimaryCountry] = useState("");
  var [secondaryCountry, setSecondaryCountry] = useState("");
  var [primaryResidence, setPrimaryResidence] = useState("");
  var [displayAlternateResidentAddress, setDisplayAlternateResidentAddress] = useState(false);
  var [primaryCity, setPrimaryCity] = useState("");
  var [primaryState_region, setPrimaryState_region] = useState("");
  var [primaryZipCode, setPrimaryZipCode] = useState("");
  var [secondaryResidence, setSecondaryResidence] = useState("");
  var [secondaryCity, setSecondaryCity] = useState("");
  var [secondaryState_region, setSecondaryState_region] = useState("");
  var [secondaryZipCode, setSecondaryZipCode] = useState("");
  const [gender, setGender] = useState("");
  var [showAdditionalName, setAdditionalName] = useState(false);
  var [selectedFile, setSelectedFile] = useState<File>();
  var [dualCitizenshipFiles, setDualCitizenshipFiles] = useState<File[]>(new Array(6));
  var [permanentResidentDocument, setPermanentResidentDocument] = useState<File>();
  var [primaryAddressObjectId, setPrimaryAddressObjectId] = useState("");
  var [secondaryAddressObjectId, setSecondaryAddressObjectId] = useState("");
  const [btnName, setBtnName] = useState("");
  const [isMessageVisible, setMessageVisibility] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [isUpdateVisible, setIsUpdateVisible] = useState<boolean>(false);
  const [valueOfTotalSubAward, setvalueOfTotalSubAward] = useState<string>('');
  const [isValidTotal, setIsValidTotal] = useState<boolean>(true);
  const [selectedVettingcategory, setSelectedVettingcategory] = useState<string | null>(null);
  const [selectedCountryOption, setSelectedCountryOption] = useState<string | null>(null);
  const [location, setLocation] = useState<string>('');
  const [isValidLocation, setIsValidLocationInput] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date | null | undefined>();
  const [isValidStartDate, setIsValidStartDate] = useState<boolean>(true);
  const [endDate, setEndDate] = useState<Date | null | undefined>();
  const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);
  const [valueOfSolicitationAwardNumber, setValueOfSolicitationAwardNumber] = useState<string>('');
  const [isValidSolicitationAwardNumber, setSolicitationAwardNumber] = useState<boolean>(true);
  const [valueofpurposeofProposed, setPurposeofProposed] = useState<string>('');
  const [isValidPurposeofProposed, setIsValidPurposeofProposed] = useState<boolean>(true);
  const [errorsCount, setErrorsCount] = useState<string[]>([]); 
  const [subAwardObjectName, setSubAwardObjectName] = useState<string>("");
  
  const [inputisValidTotal, setInputisValidTotal] = useState('');
  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputGender, setInputGender] = useState('');
  const [inputusCitizen, setInputusCitizen] = useState('');
  const [inputCoutryofCitizenship, setInputCoutryofCitizenship] = useState('');
  const [inputGovernmentIdentificationType, setInputGovernmentIdentificationType] = useState('');
  const [inputGovernmentIssuedID, setInputGovernmentIssuedID] = useState('');
  const [inputIDCountryOfIssuance, setInputIDCountryOfIssuance] = useState('');
  const [inputDualCitizenship, setInputDualCitizenship] = useState('');
  const [inputcityVillage, setInputcityVillage] = useState('');
  const [inputDistrict, setInputDistrict] = useState('');
  const [inputCountryOfBirth, setInputCountryOfBirth] = useState('');
  const [inputGovernorate, setInputGovernorate] = useState('');
  const [inputDateOfBirth, setInputDateOfBirth] = useState('');
  const [inputPrimaryCountry, setInputPrimaryCountry] = useState('');
  const [inputPermResident, setInputPermResident] = useState('');
  const [inputPrimaryResidence, setInputPrimaryResidence] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputPrimaryState_region, setInputPrimaryState_region] = useState('');
  const [inputPrimaryZipCode, setInputPrimaryZipCode] = useState('');
  const [inputIdNumber, setInputIdNumber] = useState('');
  const [inputDualCountry, setInputDualCountry] = useState('');
  const [inputDualGovnermentIssuanceIDType, setInputDualGovnermentIssuanceIDType] = useState('');
  const [inputDualIDCountryOfIssuance, setInputDualIDCountryOfIssuance] = useState('');
  const [inputPrimaryEmail, setInputPrimaryEmail] = useState('');
  const [inputAlternateEmail, setAlternateInputEmail] = useState('');
  const [inputOrganizationName, setInputOrganizationName] = useState('');
  const [inputPhoneOne, setInputPhoneOne] = useState('');
  const [inputPhoneTwo, setInputPhoneTwo] = useState('');
  const [inputIsCellPhoneOne, setInputIsCellPhoneOne] = useState('');
  const [inputIsCellPhoneTwo, setInputIsCellPhoneTwo] = useState('');
  const [inputValueOfTotalSubAward, setInputValueOfTotalSubAward] = useState('');
  const [inputValueofPurposeofProposed, setInputValueofPurposeofProposed] = useState('');
  const [inputSelectedCountryOption, setInputSelectedCountryOption] = useState('');
  const [inputStartDate, setInputStartDate] = useState('');
  const [inputEndDate, setInputEndDate] = useState('');

  const [buttonClicked, setButtonClicked] = useState(false);

  const [radioBtnKey, setRadioBtnKey] = useState<string>();

  var [rowData, setRowData] = useState<IGridBeneficiary[]>();

  var [colDefs, setColDefs] = useState<ColDef<IGridBeneficiary>[]>([
    { field: "name", headerName: "Beneficiary/Trainee Name", flex: 2 },
    { field: "id", headerName: "ID Number", flex: 2 },
    { field: "location", headerName: "Location", flex: 2 },
    { field: "startDate", headerName: "Start Date", flex: 2 },
    { field: "endDate", headerName: "End Date", flex: 2 },
    { field: "amount", headerName: "Amount $", flex: 2 },
    {
      headerName: "Edit",
      cellRenderer: EditCellRenderer,
      cellRendererParams: {
        onClick: handleEditClicked,
      },
      flex: 1
    },
    {
      headerName: "Delete",
      cellRenderer: DeleteCellRenderer,
      cellRendererParams: {
        setDataRow: handleGridBeneficiaryDelete,
      },
      flex: 1
    },
  ]);

  const [uploadStatus, setUploadStatus] = useState({
    isLawfulResident: false,
    citizenship: false,
  });

  const [indiID, setIndiID] = useState<string | null>(null);


  var isNotUsCitizen: boolean = usCitizen === "N";
  var isLawfulResident: boolean = permResident === "Y";
  let inputPifId = "";
  let formType = "";

  const subawardsStateRef = useRef<ISubAward[]>();
  subawardsStateRef.current = subawards;
  var beneficiaryGridData: IGridBeneficiary[] = [];



  const populateIdentityType = async () => {
    let response: IPicklist[] = await getPicklist("individual_ident_type", token.current);
    setOrgIdentityTypes(response);
  }


  const populateBeneficiary = async (pifId: string) => {
    let response: ISubAward[] = await getSubawardsByPifId(pifId, token.current);
    beneficiaryGridData = subawardsToGridBeneficiaries(response);
    setRowData(beneficiaryGridData);
    setSubawards(response);

    if (response.length > 0) {
      setDisableContinue(false);
    } else {
      setDisableContinue(true);
    }
  }



  useEffect(() => {
    if (countries.length === 0) updateCountries(token.current)
  }, [])

  useEffect(() => {
    inputPifId = state && state.pifId ? state.pifId : ""
    formType = state && state.formType ? state.formType : ""
    if (inputPifId === "") {
      pifId.current = storedPifId;
      //navigate(RouteConstants.DASHBOARD);
    }

  }, [storedPifId]);


  useEffect(() => {
    populateIdentityType();
    populateBeneficiary(storedPifId);
  }, [reRender])




  const handleCountrySelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
  }

  const handleCountrySelectBirthCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setBirthCountry(selectedCountry);
  }

  const handlePrimaryCountryIssuedBy = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setPrimCitCountryIssuedBy(selectedCountry);
  }

  const handlePrimaryIdentTypeNum = (event: any) => {
    setPrimeGovIdentNum(event as string);
  }

  const handleGovernmentIdentificationTypeChange = (event: any) => {
    const selectedGovernmentIdentification = event.target.value;
    setGovernmentIdentificationType(selectedGovernmentIdentification);
  }

  function handleSearchOnChange(event: SyntheticEvent<Element, Event>, value: ISearch) {
    if (value === null) return;

    const promise: Promise<IIndividual> = new Promise((resolve, reject) => {
      resolve(getIndividual(value.objectId, token.current));
      reject("error occured")
    })

    promise.then(res => {
      setIndividualValues(res);
    }).catch((error) => {
    })

  }

  const handleFirstNameChange = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleOtherFirstNameChange = (e: any) => {
    setOtherFirstName(e.target.value);
  };

  const handleMiddleNameChange = (e: any) => {
    setMiddleName(e.target.value);
  };

  const handleOtherMiddleNameChange = (e: any) => {
    setOtherMiddleName(e.target.value);
  };

  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value);
  };

  const handleOtherLastNameChange = (e: any) => {
    setOtherLastName(e.target.value);
  };

  const handleRankChange = (e: any) => {
    setRank(e.target.value);
  };

  const handleIdNumberChange = (e: any) => {
    setIdNumber(e.target.value);
  };

  const handleCityVillageChange = (e: any) => {
    setCityVillage(e.target.value);
  };

  const handleDistrictChange = (e: any) => {
    setDistrict(e.target.value);
  };

  const handleGovernorateChange = (e: any) => {
    setGovernorate(e.target.value);
  };

  const handleDobChange = (selectedDate: Date | null | any) => {
    setDob(selectedDate);
  };

  const handleTribalChange = (e: any) => {
    setTribal(e.target.value);
  };

  const handleOrganizationNameChange = (e: any) => {
    setOrganizationName(e.target.value);
  };

  const handleDualCitizenshipChange = (e: any) => {
    setDualCitizenship(e.currentTarget.value);
  };

  const handleUsCitizenshipChange = (e: any) => {
    setUsCitizen(e.currentTarget.value);
  };

  const handlePermResidentChange = (e: any) => {
    setPermResident(e.currentTarget.value);
  };

  const handlePermResidentIdNumberChange = (e: any) => {
    setPermResidentIdNumber(e.currentTarget.value);
  };

  const handleIsCellPhoneChange = (e: any) => {
    setIsCellPhone(e.currentTarget.value);
  };

  const handleIsAdditionalCellPhoneChange = (e: any) => {
    setIsAdditionalCellPhone(e.currentTarget.value);
  };

  const handlePhoneOneChange = (e: any) => {
    setPhoneOne(e.currentTarget.value);
    //console.log ("Inside handleONEHANGE, Radio btn key: " + radioBtnKey);
  };

  const handlePhoneTwoChange = (e: any) => {
    setPhoneTwo(e.currentTarget.value);
  };

  const handleCountryCodeOneChange = (e: any) => {
    setCountryCodeOne(e.currentTarget.value);
  };

  const handleCountryCodeTwoChange = (e: any) => {
    setCountryCodeTwo(e.currentTarget.value);
  };

  const handleEmailOneChange = (e: any) => {
    setEmailOne(e.currentTarget.value);
  };

  const handleEmailTwoChange = (e: any) => {
    setEmailTwo(e.currentTarget.value);
  };

  const handleLicenseOneChange = (e: any) => {
    setLicenseOne(e.currentTarget.value);
  };

  const handleLicenseTwoChange = (e: any) => {
    setLicenseTwo(e.currentTarget.value);
  };

  const handlePrimaryCountry = (e: any) => {
    setPrimaryCountry(e);
  };

  const handleSecondaryCountry = (e: any) => {
    setPrimaryCountry(e.currentTarget.value);
  };


  const handlePrimaryResidenceChange = (e: any) => {
    setPrimaryResidence(e.currentTarget.value);
  };

  const handleDisplayAdditionalAddress = (isShown: boolean): void => {
    setDisplayAlternateResidentAddress(isShown);
  }

  const handlePrimaryCityChange = (e: any) => {
    setPrimaryCity(e.currentTarget.value);
  };

  const handlePrimaryState_regionChange = (e: any) => {
    setPrimaryState_region(e.currentTarget.value);
  };

  const handlePrimaryZipCodeChange = (e: any) => {
    setPrimaryZipCode(e.currentTarget.value);
  };

  const handleSecondaryCityChange = (e: any) => {
    setSecondaryCity(e.currentTarget.value);
  };

  const handleSecondaryResidenceChange = (e: any) => {
    setSecondaryResidence(e.currentTarget.value);
  };

  const handleSecondaryState_regionChange = (e: any) => {
    setSecondaryState_region(e.currentTarget.value);
  };

  const handleSecondaryZipCodeChange = (e: any) => {
    setSecondaryZipCode(e.currentTarget.value);
  };

  const handleOnGenderChange = (e: any) => {
    setGender(e.currentTarget.value);
  };

  const handleBtnNameChange = (e: any) => {
    setBtnName(e.currentTarget.value);
  };

  const handleDownloadDocument = async (name: string) => {
    viewFile(name, token.current, process.env.REACT_APP_API_URL + `/viewCitDocument/${indId}/${name}`);
  };

  const handleSetSelectedFile = async (file: File) => {
    setSelectedFile(file);
  };

  const handleSetPermanentResidentFile = async (file: File) => {
    setPermanentResidentDocument(file);
  };

  const handleSetDualCitizenshipFile = async (file: File, index: number) => {
    let items = [...dualCitizenshipFiles];

    items[index] = file;

    setDualCitizenshipFiles(items);
  };

  async function handleGridBeneficiaryDelete(cellId: string) {

    let focusedSubaward: ISubAward | undefined = initializeISubAward();

    if (subawardsStateRef.current !== undefined) {
      focusedSubaward = subawardsStateRef.current.find(p => {
        return p.awardeeId === cellId

      });
    }

    if (focusedSubaward !== undefined) {

      const response = await instance(token.current).post("/beneficiary/deleteBeneficiary", focusedSubaward.subAwardObjectName);
    }
    else {
      alert("ERROR");
    }

    populateBeneficiary(storedPifId);
  }

  function handleOtherCitizenshipChange(citizehsips: ICitizenship[]) {
  }


  const handleisEditButtonClicked = () => {
    setIsEditButtonClicked(true);
  }

  const handleSelectAwardType = (eventKey: any) => {
    setSelectedVettingcategory(eventKey as string);
  }

  const handleTotalInputChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const totalSubAwardValue: string = e.target.value;

    const isValidValueInput: boolean = /^\d*\.?\d{0,2}$/.test(totalSubAwardValue)

    setvalueOfTotalSubAward(totalSubAwardValue);
    setIsValidTotal(isValidValueInput);
  }

  const handleSelectCountry = (eventKey: any) => {
    setSelectedCountryOption(eventKey as string);
  }

  const handleInputLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputLocation: string = e.target.value;

    const allowedFormat = new RegExp(/^[A-Za-z\s!@#$%^&*(),.?":{}|<>\/]+$/);

    const isValidInputLocation = allowedFormat.test(inputLocation);

    setLocation(inputLocation);
    setIsValidLocationInput(isValidInputLocation);
  };

  const handleStartDateChange = (selectedDate: Date | null | any) => {
    setStartDate(selectedDate);
  }

  const handleEndDateChange = (selectedDate: Date | null | any) => {
    setEndDate(selectedDate);
  }

  const handleSolicitationAwardNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const SolicitationAwardNumber: string = e.target.value;

    const isValidSolicitationAwardNumber: boolean = /^\d*\.?\d{0,2}$/.test(SolicitationAwardNumber)

    setValueOfSolicitationAwardNumber(SolicitationAwardNumber);
    setSolicitationAwardNumber(isValidSolicitationAwardNumber);
  }

  const handlePurposeofProposedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputPurposeofProposed: string = e.target.value;

    const allowedFormat = new RegExp(/^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/);

    const isValidInputPurposeofProposed = allowedFormat.test(inputPurposeofProposed);

    setPurposeofProposed(inputPurposeofProposed);
    setIsValidPurposeofProposed(isValidInputPurposeofProposed);
  }

  var phoneControls: IcontrolAttributes = {
    primaryOnChange: handlePhoneOneChange,
    primaryTextValue: phoneOne,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var phoneControls2: IcontrolAttributes = {
    primaryOnChange: handlePhoneTwoChange,
    primaryTextValue: phoneTwo,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var emailControls: IcontrolAttributes = {
    primaryOnChange: handleEmailOneChange,
    primaryTextValue: emailOne,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var emailControls2: IcontrolAttributes = {
    primaryOnChange: handleEmailTwoChange,
    primaryTextValue: emailTwo,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var licenseControls: IcontrolAttributes = {
    primaryOnChange: handleLicenseOneChange,
    primaryTextValue: licenseOne,
    secondaryOnChange: handleLicenseTwoChange,
    secondaryTextValue: licenseTwo,
  };


  function toDate(text: String): Date {
    var strings = text.split("/");

    return new Date(parseInt(strings[2]), parseInt(strings[0]) - 1, parseInt(strings[1]))
  }

  function getFile(citizenship: ICitizenship): any {
    var filename = citizenship.objectName;

    if (filename.includes(".")) return new File([], filename);

    return null;
  }

  function continueEvent() {

    navigate(RouteConstants.SUBAWARD_REVIEW, {
      state: {
        pifId: storedPifId,
        formType: formType
      }
    });
  }

  function saveEvent(event: any) {
    handleSaveAndCont(event);

  }

  function addNewInd(event: any) {
    setShowDetails(true);
  }

  function cancelEvent(event: any) {
    if (showDetails) {
      clearIndividualValues();
      setShowDetails(false);
    } else {
      navigate(RouteConstants.DASHBOARD)
    }
  }

  const deleteIndividualURL: string = "/individual/deleteKeyIndividual";

  async function deleteEvent(event: any) {
    const id = indiID;
    const isUserConfirmed = window.confirm('Are you sure you want to delete this record?')
    if (isUserConfirmed) {
      clearIndividualValues();
      setShowDetails(false);
    } else {
    }

    try {
      const response = await instance(token.current).post(deleteIndividualURL, id);

      // altert(response.data);

      console.log('Response', response.data);
    } catch (error) {
      console.error('Error Deleteing Individual Data:', error);
    }
  }

  async function createUpdateSubawardRequest(awardee: string, awardeeId: string) {
    
    const requestData: ISubAward = {
      pifId: storedPifId,   
      subAwardObjectName: subAwardObjectName,
      primeAwardeeId: "",
      primeAwardee: useAwardeeStore.getState().awardee || "",
      awardee: awardee, 
      awardeeId: awardeeId,
      projectName: useAwardeeStore.getState().projectName || "",
      vettingCategory: selectedVettingcategory||"",
      awardAmount: valueOfTotalSubAward,
      endDate: endDate || new Date(),
      startDate: startDate || new Date(),
      awardNumber: valueOfSolicitationAwardNumber,
      purposeOfSubAward: valueofpurposeofProposed,
      proposedCountry: selectedCountryOption||"",
      proposedState: location,
      primeAwardId: "",
      mission : storedMission,
      bureau: storedBureau,
      awardType: storedAwardType,
      partnerAwardType: PARTNER_AWARD_TYPE.BENEFICIARY,
    }

    createUpdateBeneficiary(requestData,token.current);

  }

  async function handleSaveAndCont(event: any) {

    let primeCitizenship: ICitizenship[] = [
      {
        governmentIdentificationT: selectedGovernmentIdentification,
        countryIssuedBy: primCitCountryIssuedBy,
        governmentIssuedPhotoId: idNumber,
        countryOfCitizenship: selectedCountry,
        individualId: indId,
        usOrNonUs: usCitizen,
        isDualCitizen: dualCitizenship === 'Y',
        objectName: selectedFile ? selectedFile.name : "",
        elementId: 0
      }
    ];

    const primAddress: IAddress = {
      objectId: primaryAddressObjectId,
      objectName: indId,
      district: primaryState_region,
      street: primaryResidence,
      addressLine2: "",
      country: primaryCountry,
      countryCode: [countryCodeOne, countryCodeTwo],
      state: primaryState_region,
      city: primaryCity,
      zipCode: primaryZipCode,
      phoneNumber: [phoneOne, phoneTwo],
      faxNumber: [],
      email: [emailOne, emailTwo],
      isCellPhone: [isCellPhone, isAdditionalCellPhone]

    }

    let secAddress: IAddress = {
      objectId: secondaryAddressObjectId,
      objectName: indId,
      district: secondaryState_region,
      street: secondaryResidence,
      addressLine2: "",
      country: secondaryCountry,
      countryCode: [],
      state: secondaryState_region,
      city: secondaryCity,
      zipCode: secondaryZipCode,
      phoneNumber: [],
      faxNumber: [],
      email: [],
      isCellPhone: []
    }

    let individualInputData: IIndividual = {
      indId: indId,
      pifId: storedPifId,
      dateOfBirth: dob ? format(dob, 'yyyy-MM-dd') : "",
      birthState: governorate,
      countryOfBirth: birthCountry,
      country: primaryCountry,
      alternativeCountryCode: countryCodeTwo,
      primaryCountryCode: countryCodeOne,
      idCountryOfIssuance: primCitCountryIssuedBy,
      govIssuePhotoIdType: selectedGovernmentIdentification,
      govIssuePhotoIdTypeMultipleA: "",
      govIssuePhotoIdTypeMultipleB: "",
      completeGovIssuePhotoIDNumber: idNumber,
      completeGovIssuePhotoIDNumberMultipleA: "",
      completeGovIssuePhotoIDNumberMultipleB: "",
      lawfulPermanentResidentCardNumber: permResidentIdNumber,
      organizationTitleOrRank: rank,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      nameOfIndividual: firstName + " " + lastName,
      mission: storedMission,
      licensesAndCertifications: [licenseOne],
      associatedOrgId: storedOrganisationId,
      legacyId: "",
      awardeeId: "",
      birthDistrict: district,
      birthCity: cityVillage,
      gender: gender,
      occupation: organizationName,
      tribalAffiliation: tribal,
      citizenship: [...primeCitizenship, ...filterEmptyCitizenship(otherCitizenships)],
      address: [primAddress],
      district: district,
      villageCity: cityVillage,
      stateRegion: primaryState_region,
      usCitizen: usCitizen == "Y",
      permResident: permResident == "Y",
      organizationName: organizationName,
      phone: phoneOne,
      alternatePhone: phoneTwo,
      email: emailOne,
      alternateEmail: emailTwo,
      isCellPhone: false,
      isAlternatePhoneCellPhone: false,
      title: rank,
      pifIdIndividual: "",
      idCountryOfIssuanceMultipleA: "",
      idCountryOfIssuanceMultipleB: "",
      bureau: storedBureau || "",
      otherFirstName: [otherFirstName],
      otherMiddleName: [otherMiddleName],
      otherLastName: [otherLastName],
    }

    if (secAddress.street && secAddress.street.length > 1 && secAddress.state) {
      individualInputData.address = [...individualInputData.address, secAddress];
    }

    if (licenseTwo && licenseTwo.length > 1) {
      individualInputData.licensesAndCertifications = [...individualInputData.licensesAndCertifications,
        licenseTwo];
    }

    let validate = true;

    if (firstName == '') {
      setInputFirstName("show")
      validate = false;
    } else {
      setInputFirstName("");
    }

    if (lastName == '') {
      setInputLastName("show")
      validate = false;
    } else {
      setInputLastName("");
    }

    if (gender == '') {
      setInputGender("show")
      validate = false;
    } else {
      setInputGender("");
    }

    if (usCitizen == '') {
      setInputusCitizen("show")
      validate = false;
    } else {
      setInputusCitizen("");
    }

    if (permResident == '') {
      setInputPermResident("show")
    } else {
      setInputPermResident("");
    }

    if (selectedCountry == '' || selectedCountry == "Select a Country of Citizenship") {
      setInputCoutryofCitizenship("show")
      validate = false;
    } else {
      setInputCoutryofCitizenship("");
    }

    if (selectedGovernmentIdentification == '') {
      setInputGovernmentIdentificationType("show")
      validate = false;
    } else {
      setInputGovernmentIdentificationType("");
    }

    if (selectedGovernmentIssuedID == '') {
      setInputGovernmentIssuedID("show")
    } else {
      setInputGovernmentIssuedID("");
    }

    if (primCitCountryIssuedBy == '') {
      setInputIDCountryOfIssuance("show")
      validate = false;
    }
    else {
      setInputIDCountryOfIssuance("");
    }

    if (cityVillage == '') {
      setInputcityVillage("show")
      validate = false;
    } else {
      setInputcityVillage("");
    }

    if (district == '') {
      setInputDistrict("show")
      validate = false;
    } else {
      setInputDistrict("");
    }

    if (governorate == '') {
      setInputGovernorate("show")
      validate = false;
    } else {
      setInputGovernorate("");
    }

    if (birthCountry == '') {
      setInputCountryOfBirth("show")
      validate = false;
    } else {
      setInputCountryOfBirth("");
    }

    if (!dob) {
      setInputDateOfBirth("show")
      validate = false;
    } else {
      setInputDateOfBirth("");
    }

    if (dualCitizenship == '') {
      setInputDualCitizenship("show")
      validate = false;
    } else {
      setInputDualCitizenship("");
    }

    if (idNumber == '') {
      setInputIdNumber("show")
      validate = false;
    } else {
      setInputIdNumber("");
    }

    if (primaryResidence == '') {
      setInputPrimaryResidence("show")
      validate = false;
    } else {
      setInputPrimaryResidence("");
    }

    if (primaryState_region == '') {
      setInputPrimaryState_region("show")
      validate = false;
    } else {
      setInputPrimaryState_region("");
    }

    if (phoneOne == '') {
      setInputPhoneOne("Primary Phone Number is required");
      validate = false;

    } else if (!/^[0-9()-]+$/.test(phoneOne) || phoneOne.length >= 16) {
      setInputPhoneOne("Please enter a valid phone number");
      validate = false;
    } else {
      setInputPhoneOne("");
    }

    if (phoneTwo == '') {
      setInputPhoneTwo("Alternative Phone Number is required");
      validate = false;

    } else if (!/^[0-9()-]+$/.test(phoneTwo) || phoneTwo.length >= 16) {
      setInputPhoneTwo("Please enter a valid phone number");
      validate = false;
    } else {
      setInputPhoneTwo("");
    }

    if (emailOne == '') {
      setInputPrimaryEmail("Primary email is required")
      validate = false;
    } else if (/^(?!.*@[^\s@]+\.[^\s@]+$).*$/.test(emailOne)) {
      setInputPrimaryEmail("Please enter a valid email (example@email.com).")
      validate = false;
    } else {
      setInputPrimaryEmail("");
    }

    if (organizationName == '') {
      setInputOrganizationName("show")
      validate = false;
    } else {
      setInputOrganizationName("");
    }

    if (Number(valueOfTotalSubAward) === 0) {
      setInputValueOfTotalSubAward("show")
      validate = false;
    }
    else{
      setInputValueOfTotalSubAward("");
    }

    if (valueofpurposeofProposed == '') {
      setInputValueofPurposeofProposed("show")
      validate = false;
    } else {
      setInputValueofPurposeofProposed("");
    }

    if (selectedCountryOption === null || selectedCountryOption === 'Select a Country') {
      setInputSelectedCountryOption("show")
      validate = false;
    } else {
      setInputSelectedCountryOption("");
    }

    if (!startDate) {
      setInputStartDate("show")
      validate = false;
    } else {
      setInputStartDate("");
    }

    if (!endDate) {
      setInputEndDate("show")
      validate = false;
    } else {
      setInputEndDate("");
    }

    if (!validate) return;

    try {
      console.log("upadte individual ");
      console.log(individualInputData);
      await instance(token.current).post('/individual/createOrUpdate', individualInputData).then(async response => {
        await createUpdateSubawardRequest(response.data.nameOfIndividual, response.data.indId);
      })
    } catch (error) {
      console.error('Error posting individual data:', error);
    }


    var files = new Array<File>();

    if (selectedFile) files.push(selectedFile);

    createOrUpdateCitFiles(individualInputData.citizenship, files, token.current);
    clearIndividualValues();

    setButtonClicked(true);

    event.preventDefault();
    populateBeneficiary(storedPifId);

    setDisableContinue(false);
    setShowDetails(false);
  }

  async function handleEditClicked(cellId: string) {
    //TODO: will need to add url with user ID parameter

    let focusedSubaward: ISubAward | undefined = initializeISubAward();

    if (subawardsStateRef.current !== undefined) {
      focusedSubaward = subawardsStateRef.current.find(p => {
        return p.awardeeId === cellId

      });
    }//End If
    if (focusedSubaward !== undefined) {
      var individual = await getIndividualByIndId(focusedSubaward.awardeeId, token.current);
      setIndividualValues(individual);
      var subaward = await getSubawardByObjectName(focusedSubaward.subAwardObjectName, token.current);
      setSubawardValues(subaward);
    }
    else {
      alert("ERROR");
      //setIndividualValues(subawards[0]);
    }
    setShowDetails(true);
  }

  function setSubawardValues(subAward: ISubAward) {

    clearSubAwardValues();

    setvalueOfTotalSubAward(checkNullValue(subAward.awardAmount));
    setPurposeofProposed(checkNullValue(subAward.purposeOfSubAward));
    setSelectedCountryOption(checkNullValue(subAward.proposedCountry));
    setSubAwardObjectName(checkNullValue(subAward.subAwardObjectName));
    if (subAward.awardNumber) setValueOfSolicitationAwardNumber(checkNullValue(subAward.awardNumber));

    if(subAward.endDate){
      setEndDate(new Date(subAward.endDate));
    }

    if(subAward.startDate){
      setStartDate(new Date(subAward.startDate));
    }

  }

  function setIndividualValues(ind: IIndividual) {
    //Clear out values
    clearIndividualValues();


    pifId.current = (checkNullValue(ind.pifId));
    setIndId(checkNullValue(ind.indId));
    setFirstName(checkNullValue(ind.firstName));
    setMiddleName(checkNullValue(ind.middleName));
    setLastName(checkNullValue(ind.lastName));
    setRank(checkNullValue(ind.organizationTitleOrRank));
    setCityVillage(checkNullValue(ind.birthCity));
    setDistrict(checkNullValue(ind.birthDistrict));
    if (checkNullValue(ind.dateOfBirth))
      setDob(toDate(ind.dateOfBirth));
    setGovernorate(checkNullValue(ind.birthState));
    setOrganizationName(checkNullValue(ind.occupation));
    setTribal(checkNullValue(ind.tribalAffiliation));
    setBirthCountry(checkNullValue(ind.countryOfBirth))

    //permanent residence field not found
    if (ind?.citizenship && checkNullObject(ind?.citizenship) && ind?.citizenship.length) {
      //Adding main citizenship
      setSelectedCountry(checkNullValue(ind.citizenship[0].countryOfCitizenship))
      setGovernmentIdentificationType(checkNullValue(ind.citizenship[0].governmentIdentificationT))
      setIdNumber(checkNullValue(ind.citizenship[0].governmentIssuedPhotoId));
      setPrimCitCountryIssuedBy(checkNullValue(ind.citizenship[0].countryIssuedBy))


      setUsCitizen(checkNullValue(ind.citizenship[0].usOrNonUs));
      setPermResident(checkNullValue(ind.permResident) ? "Y" : "N");
      setPermResidentIdNumber(checkNullValue(ind.lawfulPermanentResidentCardNumber));
      setDualCitizenship(checkNullValue(ind.citizenship[0].isDualCitizen));

      setSelectedFile(getFile(ind.citizenship[0]));

      if (ind.citizenship.length > 1) {
        setDualCitizenship("Y");
        let cit1: ICitizenship = initializeICitizenship;
        cit1.governmentIdentificationT = ind.citizenship[1].governmentIdentificationT;
        cit1.countryIssuedBy = ind.citizenship[1].countryIssuedBy;
        cit1.countryOfCitizenship = ind.citizenship[1].countryOfCitizenship;
        cit1.governmentIssuedPhotoId = ind.citizenship[1].governmentIssuedPhotoId;

        setOtherCitizenships([cit1])



        //setSelectedCountry(checkNullValue(ind.citizenships[1].countryOfCitizenship))
        //setGovernmentIdentificationType(checkNullValue(ind.citizenships[1].governmentIdentificationT))
        //setIdNumber(checkNullValue(ind.citizenships[1].governmentIssuedPhotoId));
        //setPrimCitCountryIssuedBy(checkNullValue(ind.citizenships[0].countryIssuedBy))
      }
      else {
        setDualCitizenship("N");
      }


    }

    if (ind?.licensesAndCertifications) setLicenseOne(checkNullValue(ind.licensesAndCertifications[0]));
    if (ind?.licensesAndCertifications && ind?.licensesAndCertifications.length > 0) setLicenseTwo(checkNullValue(ind.licensesAndCertifications[0]));

    if (checkNullObject(ind.otherFirstName) && ind.otherFirstName.length > 0)
      setOtherFirstName(checkNullValue(ind.otherFirstName[0]));

    if (checkNullObject(ind.otherMiddleName) && ind.otherMiddleName.length > 0)
      setOtherMiddleName(checkNullValue(ind.otherMiddleName[0]));

    if (checkNullObject(ind.otherLastName) && ind.otherLastName.length > 0)
      setOtherLastName(checkNullValue(ind.otherLastName[0]));

    if (checkNullObject(ind.address) && ind.address.length > 0) {
      setPrimaryResidence(checkNullValue(ind.address[0].street));
      setPrimaryCity(checkNullValue(ind.address[0].city));
      setPrimaryState_region(checkNullValue(ind.address[0].state));
      setPrimaryCountry(checkNullValue(ind.address[0].country));
      setPrimaryZipCode(checkNullValue(ind.address[0].zipCode));
      setPrimaryAddressObjectId(ind.address[0].objectId);

      if (ind.address[0].email?.length > 0) {
        setEmailOne(ind.address[0].email[0]);
      }

      if (ind.address[0].email?.length > 1) {
        setEmailTwo(ind.address[0].email[1]);
      }

      if (ind.address[0].phoneNumber?.length > 0) {
        setPhoneOne(ind.address[0].phoneNumber[0]);
      }

      if (ind?.address[0].phoneNumber?.length > 1) {
        setPhoneTwo(ind.address[0].phoneNumber[1]);
      }

      if (ind.address[0].countryCode?.length > 0) {
        setCountryCodeOne(ind.address[0].countryCode[0]);
      }

      if (ind?.address[0].countryCode?.length > 1) {
        setCountryCodeTwo(ind.address[0].countryCode[1]);
      }

      if (ind.address[0].isCellPhone?.length > 0) {
        setIsCellPhone(ind.address[0].isCellPhone[0]);
      }

      if (ind?.address[0].isCellPhone?.length > 1) {
        setIsAdditionalCellPhone(ind.address[0].isCellPhone[1]);
      }

    }
    setGender(ind.gender);


  }

  function clearSubAwardValues() {
    setvalueOfTotalSubAward("");
    setPurposeofProposed("");
    setValueOfSolicitationAwardNumber("");
    setStartDate(null);
    setEndDate(null);
  }

  function clearIndividualValues() {
    setIndId("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setIdNumber("");
    setRank("");
    setCityVillage("");
    setDistrict("");
    setDob(null);
    setGovernorate("");
    setOrganizationName("");
    setTribal("");
    setUsCitizen("");
    setPermResident("");
    setDualCitizenship("");
    setPhoneOne("");
    setPhoneTwo("");
    setPrimaryResidence("");
    setPrimaryCity("");
    setPrimaryState_region("");
    setPrimaryZipCode("");
    setBirthCountry("");
    setOtherFirstName("");
    setOtherMiddleName("");
    setOtherLastName("");
    setGender("");
    setSelectedCountry("");
    setPrimCitCountryIssuedBy("");
    setEmailOne("");
    setEmailTwo("");
    setLicenseOne("")
    setLicenseTwo("");
    setIsCellPhone("");
    setPrimaryCountry("");
    setGovernmentIdentificationType("");
    setSelectedFile(undefined);
    setIsCellPhone("")
    setIsAdditionalCellPhone("");
    setCountryCodeOne("");
    setCountryCodeTwo("");

  }



  const toggleValidation = () => {
    setMessageVisibility(!isMessageVisible);

  };


  return (
    <div>
      {storedPifId && <div className='text-start d-flex justify-content-center'><div className='pif-id-org'><b>PIF ID:</b> {storedPifId}</div></div>}

      <div style={{ display: showDetails ? "none" : "block", marginTop: "20px" }}>
        <div className="text-center">
          <button style={{ backgroundColor: "white" }} onClick={addNewInd} disabled={disableFormFields}><img src="../Add Ben-Trainee Button.png" /></button>
        </div>
        <div className="robSection">
          Beneficiary/Trainee
        </div>
        <div
          className="ag-theme-quartz"
          style={{
            height: 250,
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            fontSize: "16px",
          }}
        >

          <AgGridReact rowData={rowData} columnDefs={colDefs} />

        </div>
      </div>

      <div style={{ display: showDetails ? "block" : "none" }}>


        <div id="subaward">
          <div className="orgTitleSection fw-bold mb-0">
            <h4 className='text-start'>Subaward Information</h4>
          </div>
          <div className="awardContentSection textFormat" style={{ marginBottom: "2em" }}>
            <div className="row">
              <label style={{ color: "#D31E21" }}>* Denotes mandatory field</label>
              <label>{ }</label>
            </div>

            {/* Start of Row 1*/}
            <div className="row row-cols-2">

              <div className="col flex-fill pe-5 ps-0">
                <label className="label-div asterisk mb-2">Estimated Value of Total Subaward (USD)</label>
                <HelpDialog
                  title={"Value of Total Subaward (USD)"}
                  context='Total Estimated value over the life of the sub award'
                />
                <div className="control-div">
                  <input tabIndex={4}
                    className="form-control input-group-lg"
                    type="textbox"
                    id="valueOfTotalSubAward"
                    value={valueOfTotalSubAward}
                    onChange={handleTotalInputChangeValue}
                    style={{ borderColor: isValidTotal ? 'lightgrey' : '#D31E21', color: "black" }}
                    placeholder='Enter Estimated Value of Total Subaward (USD)'
                    maxLength={126}
                    disabled={disableFormFields} />
                </div>
                {inputValueOfTotalSubAward && <p className="error">Please fill in Value of Total Subaward(USD).</p>}
                <div>
                  {inputisValidTotal && (
                    <p style={{ color: '#D31E21' }}>
                      {inputisValidTotal}
                    </p>
                  )}
                </div>
              </div>

              <div className="col flex-fill ps-0 pe-5">
                <label className="asterisk mb-2 mt-3 ms-3">Purpose of Proposed Award or Assistance</label>
                <HelpDialog
                  title={"Purpose of Proposed Award of Assistance"}
                  context='Brief description of the activity performed under this subaward'
                />
                <div className="control-div">
                  <input type="textbox" tabIndex={10}
                    className="form-control input-group-lg"
                    id="PurposeofProposed"
                    value={valueofpurposeofProposed}
                    onChange={handlePurposeofProposedChange}
                    style={{ borderColor: isValidPurposeofProposed ? 'lightgrey' : '#D31E21' }}
                    placeholder='Purpose of Proposed Award or Assistance'
                    disabled={disableFormFields} />

                  {!isValidPurposeofProposed && (
                    <p style={{ color: '#D31E21' }}>
                      Enter valid text
                    </p>
                  )}
                </div>
                {inputValueofPurposeofProposed && <div className="error">Please fill in Purpose of Proposed Award or Assistance.</div>}
              </div>

            </div>
            {/* End of Row 1*/}


            {/* Start of Row 2*/}
            <div className="row row-cols-2">
              <div className="col-6 pe-2">
                <label className="label-div asterisk ms-3 mb-2">Country</label>
                <HelpDialog
                  title={"Country"}
                  context='Country where the work will be performed'
                />
                <Dropdown onSelect={handleSelectCountry} key={uniqueKey()} tabIndex={5}>
                  <Dropdown.Toggle variant="success" id="dropdown-country" size="lg"
                    style={{
                      width: '96%',
                      fontSize: "1rem",
                      backgroundColor: 'white',
                      color: 'black',
                      borderColor: 'lightgrey',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginLeft: '15px'
                    }}
                    disabled={disableFormFields}
                  >
                    {selectedCountryOption || 'Select a Country'}
                    <span className="caret" style={{ marginRight: 'auto', borderColor: 'lightgrey transparent transparent' }}></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: '96%', backgroundColor: 'white', maxHeight: '1000%', overflowY: 'auto' }} className='disabledDropsDowns'>
                    {countries.map((option, i) => (
                      <Dropdown.Item key={i} eventKey={option.country_name}>
                        {option.country_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {inputSelectedCountryOption && <p className="error">Please select a Country.</p>}
              </div>

              <div className="col-6">
                <label className="label-div-optional mb-2 mt-3 ms-4">Solicitation/Award Number</label>
                <HelpDialog
                  title={"Solicitation/Award Number"}
                  context='USAID Solicitation or award number associated with the Prime Award'
                />
                <div className="control-div">

                  <input tabIndex={9}
                    style={{ color: "black" }}
                    className="form-control input-group-lg"
                    type="textbox" maxLength={100}
                    id="valueOfSolicitationAwardNumber"
                    value={valueOfSolicitationAwardNumber}
                    onChange={handleSolicitationAwardNumber}
                    placeholder='Enter Solicitation/Award Number'
                    disabled={disableFormFields} />

                </div>
              </div>
            </div>
            {/* End of Row 2*/}

            {/* Start of Row 3*/}
            <div className="row row-cols-2">
              <div className="col-6 pe-2">

                <label className="label-div mb-2 asterisk ms-3">Estimated Subaward Start Date</label>
                <HelpDialog
                  title={"Estimated Subaward Start Date"}
                  context='Date the subaward is scheduled to begin'
                />
                <div className="control-div rounded-3">
                  <DatePicker format={('dd-MMM-yyyy')} tabIndex={7}
                    style={{ width: "100%", color: "black", fontFamily: "sans-serif" }}
                    size='md'
                    id='startDateInput'
                    value={startDate}
                    onChange={handleStartDateChange}
                    placeholder="dd-mmm-yyyy"
                    placement='topStart'
                    disabled={disableFormFields}
                  />
                </div>
                {inputStartDate && <p className="error">Please fill in a Start Date.</p>}
                {!isValidStartDate && <p style={{ color: '#D31E21' }}>Invalid date format. Use DD-MMM-YYYY.</p>}
              </div>

              <div className="col flex-fill ps-0 pe-5">
                <label className="label-div mb-2 asterisk">Estimated Subaward End Date</label>
                <HelpDialog
                  title={"Estimated Subaward End Date"}
                  context='Date the subaward is scheduled to end'
                />
                <div className="control-div">
                  <DatePicker format={('dd-MMM-yyyy')} tabIndex={8}
                    style={{ width: "100%", color: "black", fontFamily: "sans-serif" }}
                    size='md'
                    id='endDateInput'
                    value={endDate}
                    onChange={handleEndDateChange}
                    placeholder="dd-mmm-yyyy"
                    placement='topStart'
                    disabled={disableFormFields}
                  />
                </div>
                {inputEndDate && <p className="error">Please fill in an End Date.</p>}
                {!isValidEndDate && <p style={{ color: '#D31E21' }}>Invalid date format. Use DD-MMM-YYYY.</p>}
              </div>
            </div>
            {/* End of Row 3*/}

          </div>

        </div>

        <div className="robSection">
          Beneficiary/Trainee
        </div>

        <div className="contentSection-redesign" style={{ marginTop: "1em" }}>

          {errorsCount.length > 0 && (
            <div>
              <p style={{ color: 'red', fontWeight: 'bold' }}> Please correct errors highlighted below.</p>
            </div>
          )}


          <div
            className="textFormat"
            style={{ marginBottom: "2em" }}
          >
            <div className="row ms-2 mb-2">
              <label style={{ color: "red" }}>* Denotes mandatory field</label>
            </div>
            <div>
              <OrganizationSearch
                section={"individual"}
                placeHolderText={"Start typing at least 2 minimum characters:"}
                searchOnChange={handleSearchOnChange} disabled={false} />
            </div>

            <div>
              <div className="ms-1 mt-3">
                <div className="col-9 mb-1"> <label>Name (listed on government photo ID) </label>
                </div>
              </div>
              <div className="contentSection textFormat">
                <div className="row">
                  <div className="col-4">
                    <div className="row"><label className="asterisk mb-2">First Name</label></div>
                    <div className="row control-spacing">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter First Name"
                        onChange={handleFirstNameChange}
                        value={firstName}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row"><label className="ms-2 mb-2">Middle Name</label></div>
                    <div className="row control-spacing">
                      <input
                        className="form-control input-group-lg"
                        maxLength={100}
                        type="textbox"
                        placeholder="Enter Middle Name"
                        onChange={handleMiddleNameChange}
                        value={middleName}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row"><label className="asterisk mb-2">Last Name</label></div>
                    <div className="row control-spacing">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter Last Name"
                        onChange={handleLastNameChange}
                        value={lastName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    {inputFirstName && (
                      <p className="error" style={{ marginLeft: "8px" }}>
                        First Name is required
                      </p>
                    )}
                  </div>
                  <div className="col-4">&nbsp;</div>
                  <div className="col-4">
                    {inputLastName && (
                      <p style={{ color: 'red', marginLeft: '8px' }}>
                        Last Name is required
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="ms-1 mt-3"> Other Names Used </div>
              </div>
              <div className="contentSection textFormat">
                <div className="row">
                  <div className="col-4">
                    <div className="row"><label className="ms-2 mb-2">First Name</label></div>
                    <div className="row control-spacing">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter First Name"
                        onChange={handleOtherFirstNameChange}
                        value={otherFirstName}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row"><label className="ms-2 mb-2">Middle Name</label></div>
                    <div className="row control-spacing">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter Middle Name"
                        onChange={handleOtherMiddleNameChange}
                        value={otherMiddleName}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row"><label className="ms-2 mb-2">Last Name</label></div>
                    <div className="row control-spacing">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter Last Name"
                        onChange={handleOtherLastNameChange}
                        value={otherLastName}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="textFormat row">
                <div className="col-9 ms-1 mt-3">
                  <label>Place of Birth</label>
                  <HelpDialog
                    title={'Place of Birth'}
                    context={textBirth}
                  />
                </div>
              </div>
              <div className="contentSection textFormat">
                <div className="row">
                  <div className="col-6">
                    <label className="asterisk mb-2">Village/City</label>
                    <div>
                      <input
                        className="form-control input-group-lg"
                        placeholder="Enter Village/City"
                        maxLength={100}
                        type="textbox"
                        onChange={handleCityVillageChange}
                        value={cityVillage}
                      />
                    </div>
                    <div>
                      {inputcityVillage && (
                        <p style={{ color: 'red', marginLeft: '7px' }}>
                          Village/City is required
                        </p>
                      )} </div>
                  </div>
                  <div className="col-6">
                    <label className="asterisk mb-2">District (N/A if not applicable)</label>
                    <div>
                      <input
                        className="form-control input-group-lg mb-1"
                        placeholder="Enter District"
                        maxLength={100}
                        type="textbox"
                        onChange={handleDistrictChange}
                        value={district}
                      />
                    </div>
                    <div>
                      {inputDistrict && (
                        <p style={{ color: 'red', marginLeft: '5px' }}>
                          District is required
                        </p>
                      )} </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <label className="asterisk mb-2">
                      Governorate, Province, or State
                    </label>
                    <div>
                      <input
                        className="form-control"
                        placeholder="Enter Governorate, Province, or State"
                        maxLength={150}
                        type="textbox"
                        onChange={handleGovernorateChange}
                        value={governorate}
                      />
                    </div>
                    <div>
                      {inputGovernorate && (
                        <p style={{ color: 'red', marginLeft: '5px' }}>
                          Governorate, Province, or State is required
                        </p>
                      )} </div>
                  </div>
                  <div className="col-4">
                    <label className="asterisk mb-2">
                      Country of Birth
                    </label>
                    <div>
                      <select className="form-select form-select-md mb-2" value={birthCountry} onChange={handleCountrySelectBirthCountry}>
                        <option value="" disabled hidden selected>
                          Select Country of Birth
                        </option>
                        {countries?.map((item, index) => (
                          <option key={index} value={item.country_name}>{item.country_name}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      {
                        inputCountryOfBirth && (
                          <p style={{ color: 'red', marginLeft: '5px' }}>
                            Country of Birth is required
                          </p>
                        )
                      } </div>
                  </div>
                  <div className="col-4">
                    <label className="asterisk mb-2">Date of Birth</label>
                    <div className="mb-2">
                      <DatePicker format={('dd-MMM-yyyy')}
                        style={{ width: "100%", color: "black", fontFamily: "sans-serif" }}
                        size='md'
                        id='dateOfBirth'
                        value={dob}
                        onChange={handleDobChange}
                        placeholder="dd-mmm-yyyy"
                        placement='topStart'
                      />
                      <div>
                        {
                          inputDateOfBirth && (
                            <p style={{ color: 'red', marginLeft: '5px' }}>
                              Date of Birth is required
                            </p>
                          )}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label className="asterisk mt-3 mb-2">
                  Gender (listed on government photo ID)
                </label>
                <HelpDialog
                  title={"Gender on government-issued photo ID"}
                  context='Gender as it appears on the government-issued identification'
                />
              </div>
              <div className="col-6">
                <label className="mt-3 asterisk">US Citizen</label>
                <HelpDialog
                  title={"U.S. Citizen?"}
                  context='Yes for U.S. citizen and No for non-U.S. citizen'
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  className="form-control input-group-lg"
                  maxLength={50}
                  type="textbox"
                  placeholder="Enter gender on government-issued photo ID"
                  onChange={handleOnGenderChange}
                  value={gender}
                />
                {inputGender && (
                  <p style={{ color: 'red' }} className="ms-2">
                    Gender is required
                  </p>
                )}
              </div>
              <div className="col-6">
                <div className="form-check-inline">
                  <input
                    id="radio-yes"
                    type="radio"
                    name="citizenship"
                    value={"Y"}
                    checked={usCitizen === "Y"}
                    onChange={handleUsCitizenshipChange}
                  />
                  <label className="form-check-label" htmlFor="radio-yes">Yes</label>
                </div>
                <div className="form-check-inline">
                  <input
                    id="radio-no"
                    type="radio"
                    name="citizenship"
                    value={"N"}
                    checked={usCitizen === "N"}
                    onChange={handleUsCitizenshipChange}
                  />
                  <label className="form-check-label" htmlFor="radio-no">No</label>
                </div>
                <div>
                  {inputusCitizen && (
                    <p style={{ color: 'red' }} className="ms-2">
                      Selecting a US Citizen value is required
                    </p>
                  )} </div>
              </div>
            </div>
          </div>
          {/* <div className="row" style={{ margin: "1em" }}> */}

          {isNotUsCitizen && (
            <div>
              <div className="row">
                <div className="col-6">
                  <label className="asterisk">
                    US Lawful Permanent Resident
                  </label>
                  <HelpDialog
                    title={"U.S. Lawful Permanent Resident?"}
                    context={textLawful}
                  />
                  <div>
                    <div className="form-check-inline">
                      <input
                        // style={{ marginLeft: "-10px" }}
                        id="residency-yes"
                        type="radio"
                        name="residency"
                        value={"Y"}
                        checked={permResident === "Y"}
                        onChange={handlePermResidentChange}
                      />
                      <label htmlFor="residency-yes">Yes</label>
                    </div>
                    <div className="form-check-inline">
                      <input
                        id="residency-no"
                        type="radio"
                        name="residency"
                        value={"N"}
                        checked={permResident === "N"}
                        onChange={handlePermResidentChange}
                      />
                      <label htmlFor="residency-no">No</label>
                    </div>
                  </div>
                </div>
                {isLawfulResident && (
                  <div className="col-6">

                    <label className="asterisk mb-2">
                      Lawful Permanent Resident Card Number #
                    </label>
                    <div className="row">
                      <div className="col">
                        <UploadControl
                          handleTextboxValueChange={
                            handlePermResidentIdNumberChange
                          }
                          textboxValue={permResidentIdNumber}
                          title={"Upload Document"}
                          content={"Upload Lawful Permanent Resident ID Card:"}
                          text={"*Upload Lawful Resident Card Photo ID"}
                          selectedFile={permanentResidentDocument}
                          onSetSelectedFile={handleSetPermanentResidentFile}
                          onDownloadDocument={handleDownloadDocument}
                          field="isLawfulResident"
                          isUploaded={uploadStatus.isLawfulResident}

                        ></UploadControl>

                      </div>

                    </div>
                  </div>
                )}

              </div> { /* end of Row */}
            </div>
          )}
          <div style={{ marginBottom: "1em" }}>
            <div className="textFormat row">
              <div className="col-9 ms-1">
                <label> Citizenship </label>
                <HelpDialog
                  title={"Citizenship"}
                  context={textCitizenship}
                />
              </div>
            </div>
            <div className="contentSection textFormat">
              <div className="row mb-3">
                <div className="col-6">
                  <label className="asterisk mb-2">
                    Country of Citizenship
                  </label>
                  <div>
                    <select className="form-select form-select-md" value={selectedCountry} onChange={handleCountrySelectChange}>
                      <option value="" disabled hidden selected>
                        Select Country of Citizenship
                      </option>
                      {countries?.map((item, index) => (
                        <option key={index} value={item.country_name}>{item.country_name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {inputCoutryofCitizenship && (
                      <p style={{ color: 'red', marginLeft: "1em" }}>
                        Country of Citizenship is required
                      </p>
                    )} </div>
                </div>

                <div className="col-6">
                  <label className="asterisk mb-2">
                    Government Identification Type
                  </label>
                  <div>
                    <select className="form-select form-select-md"
                      value={selectedGovernmentIdentification} onChange={handleGovernmentIdentificationTypeChange}>
                      <option selected> Select Government Identity type</option>
                      {orgIdentityTypes?.map((item, index) => (
                        <option key={index} value={item.display_name}>{item.display_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{ marginLeft: '15px' }}>
                    {inputGovernmentIdentificationType && (
                      <p style={{ color: 'red' }}>
                        Government Identification Type is required
                      </p>
                    )} </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="asterisk mb-2">
                    Government-Issued Photo ID #
                  </label>
                  <div>
                    <UploadControl
                      type="textbox"
                      maxLength={38}
                      placeholder="Enter Government-Issued Photo ID #"
                      handleTextboxValueChange={handleIdNumberChange}
                      textboxValue={idNumber}
                      title={"Upload Document"}
                      content={"Upload Government Issued Photo ID:"}
                      text={"*Upload ID"}
                      selectedFile={selectedFile}
                      onSetSelectedFile={handleSetSelectedFile}
                      onDownloadDocument={handleDownloadDocument}
                      field="field2"
                      isUploaded={uploadStatus.citizenship}
                    > </UploadControl>
                  </div>
                  <div style={{ marginLeft: '15px' }}>
                    {

                      inputIdNumber && (
                        <p style={{ color: 'red' }}>
                          Government Issued Photo ID # is required
                        </p>

                      )
                    } </div>

                </div>
                <div className="col-6">
                  <label className="asterisk mb-2">
                    Country Issued By
                  </label>
                  <div>
                    <select className="form-select form-select-md" value={primCitCountryIssuedBy} onChange={handlePrimaryCountryIssuedBy}>
                      <option value="" disabled hidden selected>
                        Select Country Issued By
                      </option>
                      {countries?.map((item, index) => (
                        <option key={index} value={item.country_name}>{item.country_name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {inputIDCountryOfIssuance && (
                      <p style={{ color: 'red', marginLeft: "15px" }}>
                        Country Issued By is required
                      </p>
                    )} </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="asterisk">Dual Citizenship</label>
              <HelpDialog
                title={"Dual Citizen"}
                context={textDual}
              />

              <div className="row">
                <div className="d-flex align-items-center col-1 form-check-inline">
                  <input
                    id="dual-yes"
                    type="radio"
                    name="dualCitizenship"
                    value={"Y"}
                    checked={dualCitizenship === "Y"}
                    onChange={handleDualCitizenshipChange}
                  />
                  <label htmlFor="dual-yes">Yes</label>
                </div>
                <div className="d-flex align-items-center col-1 form-check-inline">
                  <input
                    // style={{ marginLeft: "1em", }}
                    id="dual-no"
                    type="radio"
                    name="dualCitizenship"
                    value={"N"}
                    checked={dualCitizenship === "N"}
                    onChange={handleDualCitizenshipChange}
                  />
                  <label htmlFor="dual-no">No</label>
                </div>
              </div>
              <div>
                {inputDualCitizenship && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>
                    Selecting a Dual Citizenship value is required
                  </p>
                )} </div>
            </div>
          </div>

          <div>
            <DualCitizenship
              hasDualCitizenship={dualCitizenship}
              title="Dual Citizenship"
              selectedFiles={dualCitizenshipFiles}
              setSelectedFiles={handleSetDualCitizenshipFile}
              onDownloadDocument={handleDownloadDocument}
              setCountryData={countries}
              setIdentityData={orgIdentityTypes}
              value={otherCitizenships}
              onChange={handleOtherCitizenshipChange} disabled={false} />
          </div>

          <div>
            {/* Put these validation messages in Additional Address */}
            {
              inputPrimaryCountry && (
                <p style={{ color: 'red', marginTop: '45px', marginLeft: '8px' }}>
                  Country of Residence is required
                </p>
              )}</div>

          <div>
            {inputPrimaryResidence && (
              <p style={{ color: 'red', marginLeft: '8px' }}>
                Address of Residence is required
              </p>
            )} </div>
          <div>
            {inputCity && (
              <p style={{ color: 'red', marginLeft: '8px' }}>
                City is required
              </p>
            )} </div>
          <div>
            {inputPrimaryState_region && (
              <p style={{ color: 'red', marginLeft: '8px' }}>
                State/Province/Region is required.
              </p>
            )} </div>
          <div>
            {inputPrimaryZipCode && (
              <p style={{ color: 'red', marginLeft: '8px' }}>
                Zip Code is required
              </p>
            )} </div>

          <div>
            <IndividualAddressField
              id="primaryID"
              name="primaryAddress"
              title={`Address of Residence`}
              helpText="Please provide the full address of residence"
              addressPlaceholderText="Enter Address of Residence"
              provincePlaceholderText="Enter Province/Region"
              isAddtional={displayAlternateResidentAddress}
              allowAdditional={false}
              handleShowIsAddtional={handleDisplayAdditionalAddress}
              altTitle={`Additional Address of Residence`}
              address1Value={primaryResidence}
              address1EventChangeHandler={handlePrimaryResidenceChange}
              province1Value={primaryState_region}
              province1EventChangeHandler={handlePrimaryState_regionChange}
              address2Value={secondaryResidence}
              address2EventChangeHandler={handleSecondaryResidenceChange}
              province2Value={secondaryState_region}
              province2EventChangeHandler={handleSecondaryState_regionChange}
              disabled={disableFormFields}
            />
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <AdditionalIsCellPhoneNumber
                key={radioBtnKey}
                btnName="btnOne"
                title={"Primary Phone Number"}
                isCell={isCellPhone}
                isAddtionalCell={isCellPhone}
                onIsCellChange={handleIsCellPhoneChange}
                onIsAddtionalCellChange={handleIsAdditionalCellPhoneChange}
                controlAttributes={phoneControls}
                isAddNumberNeeded={false}
                countryCode={countryCodeOne}
                countryCodeOnChange={handleCountryCodeOneChange}
                isPrimary={true} disabled={false} />
            </div>

            <div className="col-6">
              <AdditionalIsCellPhoneNumber
                key={radioBtnKey}
                btnName="btnTwo"
                title={"Alternative Phone Number"}
                isCell={isAdditionalCellPhone}
                isAddtionalCell={isAdditionalCellPhone}
                onIsCellChange={handleIsAdditionalCellPhoneChange}
                onIsAddtionalCellChange={handleIsAdditionalCellPhoneChange}
                controlAttributes={phoneControls2}
                countryCode={countryCodeTwo}
                countryCodeOnChange={handleCountryCodeTwoChange}
                isAddNumberNeeded={false}
                isPrimary={false} disabled={false} />

            </div>
            <div className="row">
              <div className="col-6">
                {inputPhoneOne && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>
                    {inputPhoneOne}
                  </p>
                )} </div>
              <div className="col-6">
                {inputPhoneTwo && (
                  <p style={{ color: 'red', marginLeft: '20px' }}>
                    {inputPhoneTwo}
                  </p>
                )} </div>
            </div>
          </div>


          <div className="row">
            <div className="col-6">
              <AdditionalEmail
                title={"Primary Personal Email"}
                isAddEmailNeeded={false}
                controlAttributes={emailControls}
                isRedStarNeeded={true}
                isPrimary={true}
                isPersonal={true} disabled={false}
                defaultText="Enter Primary Personal Email: User@domain.com" />

            </div>
            <div className="col-6 mt-1">
              <AdditionalEmail
                title={"Alternate Email"}
                isAddEmailNeeded={false}
                controlAttributes={emailControls2}
                isRedStarNeeded={false}
                isPrimary={false}
                isPersonal={false} disabled={false}
                defaultText="Enter Alternate Email: User@domain.com" />

            </div>
          </div>
          <div className="row">
            <div className="col-6 ms-1">
              {inputPrimaryEmail && (
                <p style={{ color: 'red' }}>
                  {inputPrimaryEmail}
                </p>
              )} </div>
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <label className="ms-2 mb-2">
                Tribal Affiliation (if applicable)
              </label>
              <div>
                <input
                  className="form-control"
                  maxLength={100}
                  placeholder="Enter Tribal Affiliation"
                  type="textbox"
                  onChange={handleTribalChange}
                  value={tribal}
                />
              </div>
            </div>

            <div className="col-6">
              <label className="asterisk mb-2">
                Organization (Current Employer)
              </label>
              <div>
                <input
                  className="form-control"
                  maxLength={100}
                  placeholder="Select Current Employer"
                  type="textbox"
                  onChange={handleOrganizationNameChange}
                  value={organizationName}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">

            </div>
            <div className="col-6">
              {inputOrganizationName && (
                <p style={{ color: 'red', marginLeft: '3px' }}>
                  Organization name is required
                </p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <AdditionalLicense
                title={"Professional Licenses & Certifications"}
                controlAttributes={licenseControls} disabled={false} />
            </div>
            <div className="col-6 mt-3">
              <label className="ms-2 mb-2">Organization Title or Rank</label>
              <div>
                <input
                  className="form-control"
                  maxLength={200}
                  type="textbox"
                  onChange={handleRankChange}
                  value={rank}
                  placeholder="Enter Organization Role/Title/Rank"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-5 px-1 d-flex justify-content-center gap-4 flex-sm-col flex-wrap align-items-center" style={{ marginTop: '20px' }}>

        <button className="btn loginButton usaid-blue-redesign" onClick={cancelEvent}>Cancel</button>
        <button className="btn loginButton usaid-blue-redesign" onClick={saveEvent} disabled={disableFormFields}
          style={{ display: showDetails ? "block" : "none" }}>Save Beneficiary/Trainee</button>
        <button className="btn loginButton usaid-blue-redesign" onClick={continueEvent}
          style={{ display: showDetails ? "none" : "block" }}>Continue</button>
      </div>
    </div>
  );
}
