import { HelpConstants } from "./HelpConstants";

export class LinkBodyContent{

    private contentTitle = new HelpConstants();

    public HelpLinks = [
        {
            title: this.contentTitle.Overview,
            content: <div><h3>{this.contentTitle.Overview}</h3></div>
        },
        {
            title: this.contentTitle.Account_Setup,
            content: <div><h3>{this.contentTitle.Account_Setup}</h3></div>
        },
        {
            title: this.contentTitle.PIF_Desktop,
            content: <div><h3>{this.contentTitle.PIF_Desktop}</h3></div>
        },
        {
            title: this.contentTitle.Sub_PIF,
            content: <div><h3>{this.contentTitle.Sub_PIF}</h3></div>
        },
        {
            title: this.contentTitle.Ind_PIF,
            content: <div><h3>{this.contentTitle.Ind_PIF}</h3></div>
        },
        {
            title: this.contentTitle.Other_PIF,
            content: <div><h3>{this.contentTitle.Other_PIF}</h3></div>
        },
        {
            title: this.contentTitle.Non_PIF,
            content: <div><h3>{this.contentTitle.Non_PIF}</h3></div>
        },
        {
            title: this.contentTitle.Add_Ind,
            content: <div><h3>{this.contentTitle.Add_Ind}</h3></div>
        },
        {
            title: this.contentTitle.Add_Org,
            content: <div><h3>{this.contentTitle.Add_Org}</h3></div>
        },
        {
            title: this.contentTitle.Key_Ind,
            content: <div><h3>{this.contentTitle.Key_Ind}</h3></div>
        },
        {
            title: this.contentTitle.Revetting,
            content: <div><h3>{this.contentTitle.Revetting}</h3></div>
        },
        {
            title: this.contentTitle.PIF_Desktop_Search,
            content: <div><h3>{this.contentTitle.PIF_Desktop_Search}</h3></div>
        },
        {
            title: this.contentTitle.PIF_Sub,
            content: <div><h3>{this.contentTitle.PIF_Sub}</h3></div>
        },
        {
            title: this.contentTitle.Sec_Message,
            content: <div><h3>{this.contentTitle.Sec_Message}</h3></div>
        },
        {
            title: this.contentTitle.Attach_Docs,
            content: <div><h3>{this.contentTitle.Attach_Docs}</h3></div>
        },
        {
            title: this.contentTitle.Site_Sec,
            content: <div><h3>{this.contentTitle.Site_Sec}</h3></div>
        },
        {
            title: this.contentTitle.Faq,
            content: <div><h3>{this.contentTitle.Faq}</h3></div>
        },
        {
            title: this.contentTitle.Gloss_Term,
            content: <div><h3>{this.contentTitle.Gloss_Term}</h3></div>
        }
    ]; 
}