import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/icons/chevron-down.svg";
import HelpDialog from "../../common/HelpDialog";
import "./SingleAddressField.css";

type IndividualAddressFieldProps = {
  name: string,
  id: string,
  title: string,
  helpText: string,
  addressPlaceholderText: string,
  provincePlaceholderText: string,
  isAddtional: boolean,
  allowAdditional: boolean,
  handleShowIsAddtional: any,
  altTitle: string,
  address1Value:string,
  address1EventChangeHandler: any,
  province1Value:string,
  province1EventChangeHandler: any,
  address2Value:string,
  address2EventChangeHandler: any,
  province2Value:string,
  province2EventChangeHandler: any,
  disabled:boolean;
}

export default function IndividualAddressField({ name, id, title, helpText, addressPlaceholderText, provincePlaceholderText, isAddtional, allowAdditional, handleShowIsAddtional, altTitle,address1Value,address1EventChangeHandler,province1Value,province1EventChangeHandler,address2Value,address2EventChangeHandler,province2Value,province2EventChangeHandler,disabled }: IndividualAddressFieldProps) {
  
  return (
    <>
      <div className="flex-fill mt-3">
        <div className="textFormat row">
          <div className="col-9 ms-1">
            <label> {title} </label>
            <HelpDialog
              title={title}
              context={helpText}
            />
          </div>
        </div>
        <div className="contentSection textFormat">
          <div className="row mb-2">
            <div className="col-6"><label className="asterisk">Address of Residence</label></div>
            <div className="col-6"><label className="asterisk">Province/Region</label></div>
          </div>
          <div className="row">
            <div className="col-6"><input
                  className="form-control input-group-lg"
                  maxLength={250}
                  type="textbox"
                  placeholder={addressPlaceholderText}
                  onChange={address1EventChangeHandler}
                  value={address1Value}
                  disabled={disabled}
                /></div>
            <div className="col-6"><input
                  className="form-control input-group-lg"
                  maxLength={64}
                  type="textbox"
                  placeholder={provincePlaceholderText}
                  onChange={province1EventChangeHandler}
                  value={province1Value}
                  disabled={disabled}
                /></div>
          </div>
        </div>
      </div>
      <span
        style={{ color: "#0164B(" }}
        onClick={() => handleShowIsAddtional(true)}
      >
        {allowAdditional && (
        <div className="text-end mt-1 me-3 mb-3">
          <span style={{ color: "#0164B9" }}>
            + Add Alternate {title}
          </span>
        </div>
      )}

      </span>

      {allowAdditional && isAddtional && (
        <>
          <div className="flex-fill mt-3">

            <div className="textFormat row">
              <div className="col-9 ms-1">
                <label className="ms-1 mb-1"> {altTitle} </label>
              </div>
            </div>
            <div className="rounded-2 contentSection textFormat">
              <div className="row row-cols-2 ms-3">

              <div className="col-3 flex-fill me-4">
                  <div className="row control-div">
                    <input
                      className="form-control input-group-lg"
                      maxLength={250}
                      type="textbox"
                      placeholder={addressPlaceholderText}
                      onChange={address2EventChangeHandler}
                      value={address2Value}
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="col-4 me-5">
                  <div className="row control-div">
                    <input
                      className="form-control input-group-lg"
                      maxLength={64}
                      type="textbox"
                      placeholder={provincePlaceholderText}
                      onChange={province2EventChangeHandler}
                      value={province2Value}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-end me-5 pe-2 mt-1">
              <span
                style={{ color: "#0164B(" }}
                onClick={() => handleShowIsAddtional(false)}
              >
                <div style={{ color: "#0164B9" }} className="me-3">
                  - Remove Alternate {title}
                </div>
              </span>
            </div>
          </div>
        </>
      )}
    </>

  );
}
