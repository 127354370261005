import { create } from "zustand";
import { IPicklist } from "../../Interfaces/IPicklist";
import { getOrganizationByPifId, getPicklist } from "../../Services/Api";


type OrgTypeState = {
    storedOrgTypes: IPicklist[] ;
    updateOrgTypes: (token: string)=>void;
};

export const useOrgTypeStore = create<OrgTypeState>()(
        (set) => ({
            storedOrgTypes:[],
            updateOrgTypes: (token: string) =>{
                getPicklist("org_type",token)
                .then((x:IPicklist[])=>set(() => ({storedOrgTypes : x?.slice()
                    .sort((a:IPicklist,b:IPicklist) =>a.display_name.localeCompare(b.display_name))
                    })))
                }
        })
);

