import "../subaward/SubAwardSubmission.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import {useState, useMemo, } from 'react';

import { Outlet, useLocation } from "react-router-dom";

import { useNavStore } from "../stores/NavStore";
import BreadCrumps from "../common/nav/BreadCrumps";
import RevetSelectFormType from "../subaward/selectFormType/RevetSelectFormType"
import FormNavigation from "../subaward/formNavigation/FormNavigation";

export default function RevetSelection () {

    const {storeFormType} = useNavStore();

    //*Get location 
    const urlLocation = useLocation();
    function getCurrentUrl(){
      return urlLocation.pathname as string
    }
    // Set state to hide/show elements based on SUB, PRIME, or NONAWARD
    const [showNonAward, setShowNonAward] = useState(false);
  
  
     useMemo(() => {
      // Check for Non-Award
      if (getCurrentUrl().includes("nonaward")) {
        setShowNonAward(true);
        console.log ("Ran function inside memo");
      }
      // return getCurrentUrl();
    }, [showNonAward]); // Dependency array: recompute if 'data' changes
  
      return (
      <>
      {/* MAIN */}
        <div className="me-auto navContainer">
          <BreadCrumps/>
        </div>
       <div className="subAwardWrapper">
          {(storeFormType.length>0)?<FormNavigation/>:<RevetSelectFormType/>}
        <div>
    
          <Outlet/>
  
        </div>
      </div>
      </>
      );
  }