import "../subaward/SubAwardSubmission.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import {useState, useMemo, } from 'react';
import icon_people from "../../assets/images/svg/people.svg";
import icon_person from "../../assets/images/svg/person.svg";
import {  FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faLandmark } from '@fortawesome/free-solid-svg-icons';

import NavigationBar from "../common/nav/NavMenu";
// import { PIFContext } from "./awardInfo/AwardInfo";
import { Outlet, useLocation } from "react-router-dom";

import { useNavStore } from "../stores/NavStore";
import BreadCrumps from "../common/nav/BreadCrumps";
import SelectFormType from "../subaward/selectFormType/SelectFormType";
import FormNavigation from "../subaward/formNavigation/FormNavigation";


  
  
 
export default function NonAward () {

  const {storeFormType} = useNavStore();
    const items: {value: string, label:string, iconName: string, iconClass: string }[] = [
      { value: 'organization', label: 'Organization', iconName: icon_people, iconClass: "bi-people" },
      { value: 'individual', label: 'Individual', iconName: icon_person, iconClass: "bi-person"}
    ];


    // const {setFormType,enabledForms,formType,switchForm} = FormStore();
    // const [toggleAwardeeAndFormNav,setToggleAwardeeAndFormNav] = useState(true);
    // Object for left nav links
    const navLinks =  
    {
      linkState: 'Awardee Type',
      pageTitle: "Select Award",
      breadCrumbTextLink: 'Select Awardee',
      homeLinkText: 'PVSportal',
    };


    const breadCrumbLinkText: string = "Select Awardee Type" ;
  
    const [value,setValue] = useState<string | null>(null);

    // Different text for breadcrumbs - changes with each step
    let breadCrumbTextLink = 'Select Awardee Type';

    
    // Other State Vars
    // const [ initalPage, setInitialPage] = useState(true);

    // const [selectedRadioBtn, setSelectedRadioBtn ] = useState('');
    // const [radioBtnStatus, setRadioBtnStatus ] = useState(false);
    // const [ btnVisibility, toggleBtnVisibility] = useState(false);


    // const orgId:any = document.getElementById("awardeeorganization");
    // const orgId:any = document.querySelector("#awardeeorganization");
  

    // const indId:any = document.getElementById("awardeeindividual");
    const indId:any = document.querySelector("#awardeeindividual");


  //*Get location 
  const urlLocation = useLocation();
  function getCurrentUrl(){
    return urlLocation.pathname as string
  }
  // Set state to hide/show elements based on SUB, PRIME, or NONAWARD
  const [showNonAward, setShowNonAward] = useState(false);


   useMemo(() => {
    // Check for Non-Award
    if (getCurrentUrl().includes("nonaward")) {
      setShowNonAward(true);
      console.log ("Ran function inside memo");
    }
    // return getCurrentUrl();
  }, [showNonAward]); // Dependency array: recompute if 'data' changes

  // const typeOfAward = getCurrentUrl();
  // if ( typeOfAward.includes("nonaward")) {
  //   console.log ("making sure this is called");
  //   setShowNonAward(true);
  // }


      
      // const pifID:string = useContext(PIFContext);

    return (
    <>
    {/* MAIN */}
      <div className="me-auto navContainer row">
        <BreadCrumps/>
      </div>
     <div className="subAwardWrapper">
        {(storeFormType.length>0)?<FormNavigation/>:<SelectFormType/>}
      <div>
  
        <Outlet/>

      </div>
    </div>
    </>
    );
}