import 'bootstrap/dist/css/bootstrap.css';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import icon_messageUnread from '../../../assets/images/svg/messageUnread.svg';
import icon_messageRead from '../../../assets/images/svg/messageRead.svg';
import '../HelpDialog.css';
import help_icon from "../../../assets/images/svg/info-square-fill.svg";
import React, { useEffect, useRef, useState } from "react";
import {
  //getComments,
  postComments
} from "../../../Services/Api"
import { IComment, initializeComment } from "../../../Interfaces/IComment";
import { authStore } from "../../stores/AuthStore";
import { v4 as uniqueKey } from "uuid";
import { useOidcAccessToken } from '@axa-fr/react-oidc';

export default function MessageDialog(props: { title: string, context: string, pifid: string | undefined, pifstatus: string | undefined, pifComments: IComment[] | undefined, updateComment: any }) {
  //authStore
  const { details } = authStore();
  const {accessToken} = useOidcAccessToken();
  const [open, setOpen] = React.useState(false);
  // const [comments, setComments] = React.useState<IComment[]>(props.pifComments!==undefined?props.pifComments:[]);
  const [unreadComments, setUnreadComments] = useState<boolean>(false);

  const checkUnreadComments = () => {
    if (props.pifComments == null) return false;
    else return props.pifComments?.find(x => !x.commentRead) == null ? false : true;
  }
  const [renderCount, setRenderCount] = useState<number>(0);

  useEffect(() => {
    const newComments = checkUnreadComments();
    setUnreadComments(newComments);
    //update comments to read

  }, [props.pifComments])

  useEffect(() => {
    if (open && checkUnreadComments()) {
      props.updateComment(props.pifComments);
      setUnreadComments(false);
    }
  }, [open])
  // useEffect(() => {
  //   setComments(props.pifComments===null?[]:);
  // }, [renderCount]); // rerender on renderValue change

  async function submitHandle(e: any) {
    e.preventDefault();
    let newComment: IComment = initializeComment();

    newComment.comment = e.target.commentTextArea.value;
    if (details?.email !== undefined)
      newComment.userName = (details?.email);

    if (props.pifstatus !== undefined)
      newComment.externalReason = props.pifstatus;

    if (props.pifid !== undefined) {
      await postComments(newComment, props.pifid, accessToken);
      setRenderCount(prevCount => prevCount + 1);
    }
  } //end submitHandle


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
      fontWeight: 'bold',
    }
  }));


  return (
    <>
      <IconButton onClick={handleClickOpen}>
        {/* <img  src={help_icon} /> */}
        <img className='message_icon' src={unreadComments ? icon_messageUnread : icon_messageRead} alt="message Icon" />
      </IconButton>
      <div id="MessageDialogWrapper">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 3 }} id="customized-dialog-title" className="help-title">
            {/* <img  src={help_icon} />  {props.title} */}
            <img className='message_icon' src={icon_messageUnread} alt="message Icon" /> <span style={{ marginLeft: "2%" }}>   PIF COMMENTS</span>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              img: { help_icon },
              whiteSpace: "pre-wrap"
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box>
              {
                <Typography variant='body2'>
                  {(props.pifComments === null) || props.pifComments?.length == 0 ? "No comments yet" : ""}
                </Typography>
              }
              {props.pifComments?.map((item, index) => (
                  <Box key={uniqueKey()} sx={
                    {
                      border: '2px solid grey',
                      borderRadius: 2,
                      width: 500,
                      marginBottom:'4px'
                    }} alignItems="left" gap={4} p={2}>
                    <div style={{ width: "490px" }}>
                      <Typography variant='body2'>From: {item.internalReason ? "Vetting Official" : item.userName}</Typography>
                      <Typography variant='body2'>Date: { item.commentDate}</Typography>
                      <Typography variant='body2'>PIF Status: {item.externalReason}</Typography>
                      <Typography variant='body2' sx={{fontWeight: 'bold' }}>{item.comment}</Typography>
                    </div>
                  </Box>
              ))
              }
              <br></br>
              <br></br>
              <Box component='form' onSubmit={submitHandle} key={uniqueKey()}>
                <div id="AddCommentWrapper">
                  <label>Add a comment</label>
                  <div id="CommentAndSend" style={{ width: '100%' }}>
                    <textarea id="commentTextArea" style={{ width: '100%', height: '75px' }}>
                    </textarea>
                    <Button variant="contained" type="submit" style={{ float: "right" }} >Submit Comment</Button>
                  </div>
                </div>
              </Box>
            </Box>

            {/*}
        <Typography  component="dt" variant='h6'>
          {props.term2}
        </Typography>
        <Typography  component="dd" variant='subtitle2'>
          {props.desc2}
        </Typography> */}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>

    </>
  )
}
