import viewIcon from "../../../assets/images/svg/view.svg";

export default function ViewCellRenderer(props: any) {
  return (
    <div>
      <button
        type="button"
        style={{backgroundColor:"transparent"}}
        className="btn-link"
        onClick={() => props.onClick(props.data)}
      >
        <img src={viewIcon} alt="logo" />
      </button>
    </div>
  );
}
