import deleteIcon from "../../../assets/images/svg/delete.svg";

export default function DeleteCellRenderer(props: any) {
  return (
    <div className="d-flex justify-content-center">
      <button
        type="button"
        style={{backgroundColor:"transparent"}}
        className="btn-link"
        onClick={() => props.setDataRow(props.data?.cellId)}
      >
        <img src={deleteIcon} alt="logo" />
      </button>
    </div>
  );
}
