import "../subaward/organization/OrganizationInfo.css";
import uploadIcon from "../../assets/images/svg/file-upload-earmark.svg";
import deleteIcon from "../../assets/images/svg/delete.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import FileUploadModal from "./modals/FileUploadModal";
import { JSX } from "react/jsx-runtime";
import { getFileType } from "../../Services/Utilities";

export default function UploadControl(prop: any) {
  var [openDialog, setOpenDialog] = useState(false);
  var [disablePdfBtn, setDisablePdfBtn] = useState(false);
  var [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const { field } = prop;


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        if (prop.onSetSelectedFile) prop.onSetSelectedFile(file);
       
      }  else{
        if (prop.onSetSelectedFile) prop.onSetSelectedFile(null);
       
      }
    };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClickDelete = () => {
    if (window.confirm("Are you sure you want to delete?") === true) {
      setDisablePdfBtn(false);
      setShowDeleteBtn(false);
      if (prop.onSetSelectedFile) prop.onSetSelectedFile(null);
      //TODO: add logic to delete from database
    }
  };

  const handleClickBtnClose = () => {
    setOpenDialog(false);
    setShowDeleteBtn(false);
  };

  const handleClickBtnOkay = (selectedOption: any, selectedFile: File) => {
    
    setShowDeleteBtn(true);
    setOpenDialog(false);
    if (prop.onSetSelectedFile) prop.onSetSelectedFile(selectedFile);
    setDisablePdfBtn(true);
  };

  const handleClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
    }
  };

  const handleView = () => {
    if (prop.selectedFile) {
      if (prop.selectedFile.size > 0){
        const blob = new Blob([prop.selectedFile], { type: getFileType(prop.selectedFile.name) });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      } else {
        prop.onDownloadDocument(prop.selectedFile.name);
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="row">
          <div className="col">
            <input
              className="form-control input-group-lg"
              style={{ width: "100%" }}
              type="textbox"
              placeholder="Enter Government-Issued Photo ID #"
              onChange={prop.handleTextboxValueChange}
              value={prop.textboxValue}
              disabled={prop.disabled}
            />
          </div>
          <div className="row">
            <span className="col text-end pe-0 me-0 d-block" > 
              <button
                style={{ border: "none", textDecoration: "none", boxShadow: "none", backgroundColor: "transparent", padding:"0", margin:"0", paddingLeft:"0.25em", color:"blue" }}
                type="button"
                className="btn-link"
                onClick={handleClickOpen}
              >  
                <span style={{color:"#0164B9"}}> {prop.text}</span>
                <span>{" "}</span>
               <img src={uploadIcon} alt="upload file icon"/>
              </button>             
            </span>
          </div>
        </div>
        {prop.selectedFile && (
          <div className="row">
            <div className="col-2">
              <button
                type="button"
                style={{backgroundColor:"transparent"}}
                className="btn-link"
                onClick={handleClickDelete}
                disabled={prop.disabled}
              >
                <img src={deleteIcon} alt="delete" />
              </button>
            </div>
            <div className="col-10">
              <button 
                type="button" style={{backgroundColor:"transparent"}}
                className="btn-link"
                onClick={handleView}
                disabled={prop.disabled}
              >
                {prop.selectedFile.name}
              </button>
            </div>
          </div>
        )}
      </div>
      <FileUploadModal
                  uploadType={"organization"}
                  uploadTitle={"Upload ID Documents"}
                  uploadDesc={"Upload ID Type"}
                  uploadOptions={["Government Issued Other", "Government Issued Passport", "Unknown"]}
                  placeHolderText={"Select Upload ID Type"}
                  open={openDialog}
                  handleClickBtnClose={handleClickBtnClose}
                  handleClickBtnOkay={handleClickBtnOkay}/>
    </>
  );
}


