import { useLocation, useNavigate } from "react-router-dom";
import "./FormNavigation.css";
import { useNavStore } from "../../stores/NavStore";
import {v4 as uniqueKey} from "uuid";
import { useEffect, useState } from "react";
import { useFormProps } from "../../stores/FormProperties";

interface IProps{
    name:string;
    step: string;
    id: string;
    route:string;
    activeCards:Set<string>;
}

export const NavigationCards = ({name,step,id,route,activeCards}:IProps)=>{
    const navigate = useNavigate();
    const { storeFormType } = useNavStore();
    const { storedPifId } = useFormProps();
    const { enableNavCards } = useFormProps();
    const {pathname} = useLocation();
    
    
   
    const [enable,setEnable] = useState(false);
    const [currentTab,setCurrentTab] = useState(false);


    useEffect(()=>{
        setEnable(activeCards.has(id) || pathname.includes(id) || enableNavCards);
        setCurrentTab(pathname.includes(id));
    },[pathname]);
    

    const dynamicStyles = ()=>{
        return {
            opacity: (enable ? 1 : 0.5) as React.CSSProperties["opacity"],
            pointerEvents: (enable? "all": "none") as React.CSSProperties["pointerEvents"],
        }
    }

    return (<div className="card rounded-3 p-0" style={dynamicStyles()}
            >
            <div key ={uniqueKey()} className={`card-body award ${currentTab?'usaid-blue':''}`}>
                <a  id={id} onClick={(e) => {
                    navigate(route,{
                      state: {
                        pifId: storedPifId,
                        formType: storeFormType
                    }
                    });
                    }}>
                    <div className={`card-text fs-6 fw-bold ${currentTab?'text-white':'text-dark'}`}>
                        <p>{step}</p>
                        <p>{name}</p>
                    </div>
                </a>
            </div>
        </div>);
}

function useSpring(arg0: { opacity: import("csstype").Property.Opacity | undefined; pointerEvents: import("csstype").Property.PointerEvents | undefined; }) {
    throw new Error("Function not implemented.");
}
