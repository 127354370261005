export let text = 'A minimum of first name and last name as they appear\n';
text += 'on the Government issued photo identification';

export let textBirth = 'Complete all of the following fields :\n';
textBirth += '- Country of Birth - country where the Key Individual was born\n';
textBirth += '- Village/City - enter one only, either the village or the city where\n';
textBirth += '  the Key Individual was born\n';
textBirth += '- Governorate or Province or State of birth - enter one only, either\n';
textBirth += '  the governate or the province or the village of birth\n';
textBirth += '- Date of Birth (dd-mmm-yyyy) - date of when the Key Individual was born.\n';
textBirth += '  if the actual date of birth is unknown, enter 01/Jan/0000\n';
textBirth += '  and indicate this in the PIF comments.';

export let textLawful = 'Yes for U.S. lawful permanent resident in the U.S. and\n';
textLawful += 'No for non-permanent resident in the U.S.';
textLawful += 'If Yes was chosen for the U.S. Lawful Permanent Resident enter the card number';

export let textCitizenship = 'Complete all of the following fields:\n';
textCitizenship += '- Country of Citizenship - country of where the Key Individual is a citizen\n';
textCitizenship += '- ID country of issuance - country that issued the citizenship ID\n';
textCitizenship += '- Government-issued Photo ID Type - type of identification, example: passport\n';
textCitizenship += '- Complete Government-issued Photo ID Number - identification number\n';
textCitizenship += '  from the Government issued Photo ID that is not listed in the Complete\n';
textCitizenship += '  Government-Issued Photo ID Number Above';

export let textDual = 'Yes for citizenship in more than one country;\n';
textDual += 'No for citizenship in one country';



