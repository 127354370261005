import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import './Login.css';

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { authStore } from "../stores/AuthStore";
import { Box, Button, FormControl, FormHelperText, Input, InputLabel, Typography } from "@mui/material";
import axios from "axios";
import { RouteConstants } from "../../App";


export default function Login_forms() {
  const {setTestUserToken} = authStore();
  const navigate = useNavigate();
  const [username,setUsername] = useState<string>("");
  const [password,setPassword] = useState<string>("");
  const [error,setError] = useState<string>("");
  const {details, setDetails, testUserToken, isTestUserAuthenticated} = authStore();

  useEffect(()=>{
    if(isTestUserAuthenticated()) 
      navigate(RouteConstants.DASHBOARD, {replace: true})
  },[testUserToken])
  
  const handleLogin=async ()=>{
    const response = await axios.post(process.env.REACT_APP_API_URL+'/login',{
      username: username,
      key: password
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response=>{
      if(response.status===200 && response.data){
        const data = response.data;
          setTestUserToken(data.token);
          navigate(RouteConstants.DASHBOARD,{replace:true});
        }
        else if (response.status===401 && response.data){
          const data = response.data;
          setError(data.error);
          setPassword("");
          setUsername("");
        }
      }).catch((error) =>{
        const data = error.response.data;
        if(data?.error) setError(data?.error)
      })
    }

  return (
    <Box sx={{
      display: "flex",
      flexDirection: { sm: "row", md: "column" },
      gab: "10px",
      alignContent: "center",
      justifyContent: "center",
      marginTop: "30px",
      paddingTop: "20px"
    }}>
      <Box></Box>
      <Box sx={{ display:"flex", flexDirection:"column", width: { sm: "100%", md: "80%" }, alignContent: "center", justifyContent: "center",alignItems:"center" }}>

        { error && <Typography variant="h6" sx={{ display:"flex", padding:"10px 5px", color: "red"}}>{error}</Typography>}
        <FormControl required sx={{ }}>
          <InputLabel htmlFor="username"> Username </InputLabel>
          <Input id="username" aria-describedby="username" onChange={(e)=>{
            if(error) setError("");
            setUsername(e.target.value)
            }}/>
          <FormHelperText id="username-helper">Enter username</FormHelperText>
        </FormControl>
        <FormControl required sx={{ marginTop: "10px" }}>
          <InputLabel htmlFor="key">Key</InputLabel>
          <Input id="key" aria-describedby="key" type="password" onChange={(e)=>{
            if(error) setError("");
            setPassword(e.target.value)
            }}/>
          <FormHelperText id="keyhelper">Enter key</FormHelperText>
        </FormControl>
        <FormControl required sx={{ marginTop: "10px" }}>
          <Button disabled={username.length===0 || password.length===0} onClick={()=>handleLogin()}>Submit</Button>
        </FormControl>
      </Box>
      <Box></Box>
    </Box>
  );
}

