import { create } from "zustand";
import { persist } from 'zustand/middleware';
import { UserDetails } from "./interfaces/UserDetails";
import { StoreNames } from "./StoreNames";
import { jwtDecode } from 'jwt-decode';
import axios from "axios";

// Create type for custom hook
export type Workflows = 'individual' | 'organization' | null

interface jwtPayload{
    "sub": string;
    "iat": number;
    "exp": number;
      
}

type AuthState = {
    details: UserDetails |null;
    setDetails:(details:UserDetails)=>void;
    testUserToken: string| null;
    setTestUserToken:(token: string)=>void;
    isTestUserAuthenticated:()=>boolean;
    refreshTestUserToken: ()=>Promise<void>;
};


export const authStore = create<AuthState>()(
    persist(
        (set,get) => ({
            details:null,
            setDetails:(details:UserDetails)=>set(()=>({details:details})),
            testUserToken:null,
            setTestUserToken:(token: string)=>set(()=>({testUserToken:token})),
            isTestUserAuthenticated:()=>{
                let isAuthenticated=false;
                if(!get().testUserToken && process.env.REACT_APP_TEST_LOGIN==="true") return false;
                try{
                    const decodedToken = jwtDecode<jwtPayload>(get().testUserToken!);
                   isAuthenticated = Math.floor(Date.now()/1000) < decodedToken.exp;

                 //if difference is less than 2000 refresh the token
                    if((decodedToken.exp - Math.floor(Date.now()/1000) < 2000)&& get().testUserToken)
                        get().refreshTestUserToken();
                 
                }catch(error){
                    console.error("Invalid token");
                }
                return isAuthenticated;
            },
            refreshTestUserToken: async() => {
                try {
                  const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/refreshToken`,
                    {}, // Empty object for request body
                    {
                      withCredentials: true,
                      headers: { 'Authorization': `TestToken ${get().testUserToken}` }
                    }
                  );
              
                  if (response.status === 201 && response.data?.token) {
                    set({ testUserToken: response.data.token });
                    return response.data.token; // Optionally return the new token
                  }
                } catch (error) {
                  console.error("Unable to obtain a refreshed token", error);
                }
              }
        }), { name: StoreNames.Auth, getStorage: () => sessionStorage })
);




