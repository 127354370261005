import { create } from "zustand";
import { persist } from 'zustand/middleware';
import { AwardTypes, FormTypes, RouteConstants } from "../../App";
import { StoreNames } from "./StoreNames";
// Create type for custom hook

export type FormProperties = {
    vsuStatus: string;
    reason: string;
};

type FormProps = {
    formProperties: FormProperties;
    updateFormProperties: (_pifId: string,_vsuStatus:string, _reason: string) => void;
    clearFormProperties: () => void;
    updatePifId: (_pifId: string) => void;
    updateMission: (_mission: string) => void;
    updateOrgId: (_mission: string) => void;
    updateAwardId: (_awardId: string) => void;
    updateBureau: (_bureau: string) => void;
    updateFormAwardType: (_awardType: string) => void;
    updatePartnerAwardType: (_partnerAwardType: string) => void;
    disableFormFields: boolean;
    enableNavCards: boolean;
    storedPifId: string,
    storedOrganisationId: string;
    storedMission: string;
    storedAwardId: string;
    storedBureau: string;
    storedAwardType: string;
    storedPartnerAwardType: string;
};


export const useFormProps = create<FormProps>()(
    persist(
        (set, get) => ({
            disableFormFields: false,
            enableNavCards: false,
            displayHeaderMessage: true,
            storedOrganisationId:"",
            storedMission:"",
            storedAwardId: "",
            storedPifId:"",
            storedBureau:"",
            storedAwardType: "",
            storedPartnerAwardType: "",
            formProperties: {
                vsuStatus: "",
                reason:""
            },
            updateFormProperties: (_pifId: string, _vsuStatus: string, _reason: string) => {
                set(() => ({ formProperties: {vsuStatus: _vsuStatus, reason:_reason }, storedPifId :_pifId }));
                if (["Submitted - Clarification Requested","Submitted"].includes(get().formProperties.vsuStatus))
                    set(() => ({
                        disableFormFields: true,
                        enableNavCards: true
                    }));
                if (["Returned"].includes(get().formProperties.vsuStatus))
                    set(() => ({
                        disableFormFields: false,
                        enableNavCards: true,
                        displayHeaderMessage: true
                    }));
            },
            updatePifId: (_pifId: string) => {
                set(() => ({ storedPifId: _pifId }));
            },
            updateMission: (mission: string) => {
                set(() => ({ storedMission: mission }));
            },
            updateOrgId: (orgId: string) => {
                set(() => ({ storedOrganisationId: orgId }));
            },
            updateAwardId: (awardId: string) => {
                set(() => ({ storedAwardId: awardId }));
            },
            updateBureau: (_bureau: string) => {
                set(() => ({ storedBureau: _bureau }));
            },
            updateFormAwardType: (awardType: string) => {
                set(() => ({ storedAwardType: awardType }));
            },
            updatePartnerAwardType: (_partnerAwardType: string) => {
                set(() => ({ storedPartnerAwardType: _partnerAwardType }));
            },
            clearFormProperties: () => set(() => ({
                formProperties: {
                    vsuStatus: "",
                    reason:""
                }, 
                disableFormFields: false,
                enableNavCards: false,
                displayHeaderMessage:false,
                storedPifId:"",
                storedOrganisationId:"",
                storedMission:"",
                storedAwardId: "",
                storedBureau: "",
                storedAwardType: "",
                storedPartnerAwardType: ""
            })),
        }),
        { name: StoreNames.FormProps, getStorage: () => localStorage })
);


















