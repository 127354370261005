import "./RulesOfBehavior.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import Tiles from "../common/Tiles/Tiles";
import TilesWithButton from "../common/Tiles/TileWithButton";
import { useNavigate } from "react-router-dom";
import TilesWithBullets from "../common/Tiles/TileWithBulleting";
import { RouteConstants } from "../../App";


export default function RulesOfBehaviorAlt() {

  const navigate = useNavigate();
  const welcomeText = [
    "You are using an official United States Government system, which may be used only for authorized U.S. Government purposes. Unauthorized access or use of this system may subject you to administrative, civil, or criminal actions, as well as fines or other penalties. In accordance with Federal Regulations, employees have a duty to protect and conserve Government property and shall not use such property, or allow its use, for other than authorized purposes.",
    "\n",
    "To ensure that this service remains available to all users, this federal government computer system is continuously monitored. Information retrieved may be disclosed for any lawful purpose, including the management and maintenance of the system, to ensure the system is authorized to facilitate protection against unauthorized access, and to verify security procedures, survivability, and operational security. The use of this system by any user, authorized or unauthorized, constitutes express consent to this monitoring. All users of this system are advised that if such monitoring reveals evidence of possible abuse or criminal activity, such evidence may be provided to appropriate law enforcement officials. Except for authorized law enforcement investigations, no other attempts are made to identify individual users or their habits.",
    "\n",
    "We take precautions to maintain the security, confidentiality, and integrity of the information we collect on this site. USAID takes your privacy and the security of all Personally identifiable Information (PII), seriously. We employ various security technologies to protect the information stored on this system and routinely test our security measures to ensure they remain operational and effective.",
  ];

  const rulesOfBehavior = [
    "The PVS Information System Security Officer (ISSO) is responsible for ensuring an adequate level of protection and security is afforded to the PVS system. The requisite level of protection and security is accomplished through an appropriate mix of technical, administrative, and managerial controls including written guidance. Because written guidance cannot cover every contingency, the following Rules of Behavior are provided to further stipulate the responsibility of the users of the PVS System.",
  ];

  const understanding = [
    "Protect data in accordance with the Privacy Act of 1974.",
    "Protect sensitive information from disclosure to unauthorized individuals or groups.",
    "Acquire and use sensitive information only in accordance with the performance of my official government duties.",
    "Agency point-of-contact must protect information security by properly identifying Agency employees eligible as users of PVS.",
    "Dispose of sensitive information contained in hard copy or soft copy, as appropriate.",
    "Ensure that sensitive information is accurate and relevant for the purpose which it is collected, provided, and used.",
    "Protect my password from disclosure.",
    "Report security incidents and vulnerabilities to the PVS system ISSO.",
    "Comply with the provisions of the copyrighted software by not infringing upon or compromising (copy, distribute, manipulate, etc.) software of this system.",
    "Ensure all changes to PVS System components and data are done via approved configuration control procedures.",
    "Use government equipment in accordance with my site/Agency's policies and procedures.",
  ];

  const loginInfo = [
    "To improve security posture of the application and USAID network, effective April 9, 2021, the PVS Portal users are required to use Login.gov to access the portal.",
    "Please click the button below to login with your Login gov credentials. Users were requested to register with Login.gov by March 31, 2021 and registration is required before the login button below will work. If there are issues with your Login.gov, please contact your Vetting Support Unit (VSU) at USAID, who will engage further resources.",
  ];

  const agreement = [
    "I understand that all conditions and obligations imposed upon me by these rules apply during the time l am granted access to this system regardless of location.",
    "I understand that the PVS ISSO reserves the right, to terminate or suspend my access and use of the PVS System, without notice, if there is a violation of these Rules of Behavior.",
    "I have read and understand the Rules of Behavior governing my use of the PVS System and agree to abide by them. I understand that failure to do so may result in disciplinary action being brought against me.",
  ];

  return (
    <div className="container-fluid rulesOfBehavior parent">
      
      <div className="row textFormat">

        <div className="col-lg-6 col-md-6 col-sm*-6 d-flex flex-column leftCol line-break">
            <Tiles
              title={
                "Welcome to the Partner Vetting System Portal (PVS Portal)"
              }
              content={welcomeText}
            />
        </div> {/* End of 1st Column */}

        <div className="col-lg-6 col-md-6 col-sm*-6">
          {/* Nested rows here */}
            <Tiles
              title={"Rules of Behavior for all Users of the PVS Portal"}
              content={rulesOfBehavior}
            />
            <TilesWithBullets
              title={
                "I understand that, when using the PVS System,\n I am personally accountable for my actions and that I must:"
              }
              content={understanding}
            />
        </div> {/* End of 2nd Column */}

        {/* End of Nested rows */}
      </div> {/* End of First Row */}

      {/* Next Row here */}
      <div className="row textFormat">
        <div className="col-lg-12 col-md-12 col-sm*-12">
          <Tiles title={"PVS portal Login Information"} content={loginInfo} />
        </div>
      </div>
      {/* Last Row with Button */}
      <div className="row textFormat">
        <div className="col-lg-12 col-md-12 col-sm*-12  align-items-stretch">
          <TilesWithButton
                title={"You must agree to all of the above"}
                content={agreement}
                 onClickButton={() => {
                  navigate(RouteConstants.LOGIN_FORM, {replace: true});
                }}
                buttonName="I Agree"
          />
        </div>
      </div>

    </div>
  );
}
